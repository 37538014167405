import { useState } from 'react';
import { ConfirmationDialog } from './ConfirmationDialog';

export const useConfirmationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [promise, setPromise] = useState<any>(null);

  const confirm = (message: string): Promise<boolean> => {
    setMessage(message);
    setIsOpen(true);

    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject });
    });
  };

  const handleConfirm = () => {
    setIsOpen(false);
    if (promise) {
      promise.resolve(true);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (promise) {
      promise.resolve(false);
    }
  };

  const ConfirmationDialogComponent = (
    <ConfirmationDialog
      isOpen={isOpen}
      message={message}
      onClose={handleCancel}
      onConfirm={handleConfirm}
    />
  );

  return { confirm, ConfirmationDialogComponent };
};
