import {
  ChakraTheme,
  ComponentStyleConfig,
  extendTheme,
} from '@chakra-ui/react';
import { Card } from './components/card';

const Button: ComponentStyleConfig = {
  variants: {
    primary: {
      color: 'brand.white',
      bg: 'complete.500',
      _hover: {
        bg: 'complete.700',
        _disabled: {
          bg: 'complete.700',
        },
      },
      _active: {
        bg: 'complete.700',
      },
      shadow: 'base',
    },

    'primary-light': {
      color: 'brand.white',
      bg: 'complete.100',
      _hover: {
        bg: 'complete.500',
      },
      _active: {
        bg: 'complete.700',
      },
    },
    outline: {
      color: 'complete.500',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'complete.500',
    },
    'outline-transparent': {
      color: 'complete.500',
      border: '1px solid',
      borderColor: 'complete.500',
    },
    'outline-dark': {
      color: 'brand.darkGray',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'brand.dark',
      _hover: {
        color: 'brand.black',
        borderColor: 'brand.black',
      },
    },
    'outline-light': {
      color: 'brand.darkGray',
      bg: 'brand.white',
      border: '1px solid',
      borderColor: 'brand.dark',
      fontWeight: 400,
      _hover: {
        color: 'brand.black',
        borderColor: 'brand.black',
      },
      'outline-red': {
        color: 'brand.unresolved',
        bg: 'brand.white',
        border: '1px solid',
        borderColor: 'brand.unresolved',
      },
    },
    nav: {
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      fontWeight: 'normal',
      borderRadius: '8px',
      // height: '44px',
      margin: '7px 0',
      _hover: {
        // bg: 'brand.navActive',
        borderRadius: '8px',
      },
      _active: {
        // bg: 'brand.navActive',
        // color: 'complete.500',
        borderRadius: '8px',
        fontWeight: 'bold',
      },
      shadow: 'none',
    },
    'nav-icon': {
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      fontWeight: 'normal',
      borderRadius: '8px',
      // height: '44px',
      margin: '7px 0',
      _hover: {
        // bg: 'brand.navActive',
        borderRadius: '8px',
      },
      _active: {
        bg: 'brand.navActive',
        color: 'complete.500',
      },
    },
    'compact-nav': {
      color: 'brand.darkGray',
      bg: 'transparent',
      border: 'none',
      padding: '12px',
      _hover: {
        bg: 'brand.navActive',
        color: 'complete.500',
      },
      _active: {
        bg: 'brand.navActive',
        color: 'complete.500',
      },
    },
    awardedHover: {
      backgroundColor: '#F2F2F2',
      _hover: {
        backgroundColor: 'brand.softOrange',
        color: 'brand.orange',
      },
    },
    awarded: {
      backgroundColor: 'brand.softOrange',
      color: 'brand.orange',
    },
    //For IconButton.  Currently cannot styles outside of Button styles
    icon: { backgroundColor: 'transparent' },
  },
};

const Text: ComponentStyleConfig = {
  variants: {
    'h4-bold': {
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '33.6px',
    },
    'sub-section-header': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '33px',
    },
    'sub-header-bold': {
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '33px',
    },
    'sub-header': {
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '33px',
    },
    subtitle: {
      fontSize: '20px',
      lineHeight: '120%',
      fontWeight: '400',
    },
    'subtitle-bold': {
      fontSize: '20px',
      lineHeight: '120%',
      fontWeight: '700',
      color: 'brand.black',
    },
    'subtitle-muted': {
      fontSize: '20px',
      lineHeight: '120%',
      fontWeight: '400',
      color: 'brand.darkGray',
    },
    body: {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'brand.black',
    },
    'body-muted': {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'brand.darkGray',
    },
    'body-bold': {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'brand.black',
    },
    'body-bold-muted': {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'brand.darkGray',
    },
    'body-bold-white': {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'brand.white',
    },
    'body-sm': {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'black',
    },
    'body-sm-bold': {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'black',
    },
    'body-xs-bold': {
      fontSize: '12px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'black',
    },
    'body-sm-muted': {
      fontSize: '14px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'brand.darkGray',
    },
    'body-xs': {
      fontSize: '12px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'black',
    },
    'body-xs-muted': {
      fontSize: '12px',
      lineHeight: '150%',
      fontWeight: '400',
      color: 'brand.darkGray',
    },
    'login-xs-muted': {
      fontSize: '11px',
      lineHeight: '150%',
      fontWeight: '700',
      color: 'brand.darkGray',
      textDecoration: 'underline',
    },
    'data-label': {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '12px',
      color: 'brand.darkGray',
      textTransform: 'uppercase',
    },
  },
};

const Input: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: 'complete.500',
  },
  variants: {
    'input-xs': {
      field: {
        border: '1px solid #D1D5DB',
        height: '32px',
        fontSize: '12px',
        addon: {
          border: '1px solid #D1D5DB',
          height: '32px',
          fontSize: '12px',
        },
      },
    },
  },
};

const Select: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: 'complete.500',
  },
  variants: {
    'comment-select': {
      field: {
        fontSize: '10px',
        fontWeight: '700',
        lineHeight: '18px',
        letterSpacing: '0em',
        borderRadius: '4px',
        color: '#fff',
        border: 'none',
      },
      icon: {
        color: '#fff',
      },
    },
    'takeoff-select': {
      field: {
        backgroundColor: '#F9FAFB',
        border: '1px solid #D1D5DB',
      },
    },
  },
};
const Radio: ComponentStyleConfig = {
  variants: {
    primary: {
      control: {
        _checked: {
          background: 'complete.500',
        },
      },
    },
    'radio-xs': {
      fontSize: '12px',
    },
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'primary',
  },
};

const NumberInput: ComponentStyleConfig = {
  variants: {
    'takeoff-number-input': {
      field: {
        backgroundColor: '#F9FAFB',
        border: '1px solid #D1D5DB',
      },
    },
  },
};

const FormLabel: ComponentStyleConfig = {
  variants: {
    login: {
      fontSize: '16px',
      lineHeight: '150%',
      fontWeight: '400',
    },
    'label-sm-bold': {
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'normal',
      color: 'black',
    },
  },
};

const Badge: ComponentStyleConfig = {
  baseStyle: {
    textTransform: 'none',
  },
  variants: {
    orange: {
      bg: 'brand.softOrange',
      color: 'brand.orange',
    },
    blue: {
      bg: 'brand.softBlue',
      color: 'brand.blue',
    },
    green: {
      bg: 'brand.softGreen',
      color: 'brand.green',
    },
    red: {
      bg: 'brand.softRed',
      color: 'brand.unresolved',
    },
    purple: {
      bg: 'brand.softPurple',
      color: 'brand.purple',
    },
    teal: {
      bg: 'brand.softTeal',
      color: 'brand.teal',
    },
  },
};

const Link: ComponentStyleConfig = {
  variants: {
    'overview-link': {
      fontSize: '12px',
      color: 'complete.500',
      textDecoration: 'underline',
    },
    nav: {
      ...Button?.variants?.nav,
      margin: 0,
      height: 'inherit',
      _hover: {
        textDecoration: 'none',
      },
    },
    nav_active: {
      ...Button?.variants?.nav,
      margin: 0,
      height: 'inherit',
      _hover: {
        textDecoration: 'none',
      },

      color: 'complete.500',
      borderRadius: '8px',
      fontWeight: 'bold',
    },
  },
};

const Breadcrumb: ComponentStyleConfig = {
  variants: {
    'sub-nav': {
      link: {
        _hover: {
          textDecoration: 'none',
        },
      },
      item: {
        color: 'brand.darkGray',
        bg: 'transparent',
        border: 'none',
        fontWeight: '700',

        fontSize: '16px',
        lineHeight: '150%',
        _hover: {
          textDecoration: 'none',
        },
        _active: {
          fontWeight: '700',
        },
      },
    },
  },
};

const Accordion: ComponentStyleConfig = {
  variants: {
    'no-horizontal-padding': {
      button: {
        webkitPaddingStart: '5px',
        paddingInlineStart: '5px',
        webkitPaddingEnd: '5px',
        paddingInlineEnd: '5px',
      },
    },
  },
};

const Textarea: ComponentStyleConfig = {
  variants: {
    'takeoff-textarea': {
      border: '1px solid #D1D5DB',
    },
  },
};

export const Checkbox: ComponentStyleConfig = {
  variants: {
    'styled-checkbox': {
      control: {
        borderRadius: 2,
        border: '1px solid #D1D5DB',
        backgroundColor: '#F9FAFB',
      },
      label: {
        fontSize: 'sm',
        lineHeight: 'base',
        fontWeight: 'normal',
        color: 'black',
      },
    },
  },
};

const theme: Partial<ChakraTheme> = extendTheme({
  components: {
    Accordion,
    Button,
    Text,
    Badge,
    Select,
    FormLabel,
    Link,
    Breadcrumb,
    Radio,
    Card,
    Input,
    NumberInput,
    Textarea,
    Checkbox,
  },
  colors: {
    complete: {
      100: '#12B856',
      200: '#12B856',
      300: '#12B856',
      400: '#037632',
      500: '#037632',
      600: '#037632',
      700: '#025725',
      800: '#025725',
      900: '#025725',
    },
    diagnostics: {
      100: '#DF7098',
      200: '#DF7098',
      300: '#DF7098',
      400: '#B81257',
      500: '#B81257',
      600: '#B81257',
      700: '#760333',
      800: '#760333',
      900: '#760333',
    },
    brand: {
      main: '#E5E7EB',
      light: '#F5F5F5',
      navLight: '#F9F9F9',
      navActive: '#DDEAE2',
      mediumGray: '#E0E0E0',
      gray: '#A1A1A1',
      softGray: '#D9D9D9',
      softLight: '#DFDFDF',
      superLight: '#FAFAFA',
      hold: '#B1B1B1',
      dark: '#858585',
      mediumLight: '#767676',
      closed: '#242424',
      black: '#000000',
      white: '#FFFFFF',
      darkGray: '#5D5E60',
      green: '#12B856',
      softGreen: '#E0F5E8',
      orange: '#FB9C0D',
      softOrange: '#FEF5E9',
      purple: '#7E3ADF',
      softPurple: '#EFE3FF',
      blue: '#2BA3E7',
      softBlue: '#D1EEFF',
      softRed: '#FFEFEF',
      unresolved: '#FF4D6D',
      resolved: '#2FB219',
      greenDark: '#2A7347',
      teal: '#008080',
      softTeal: '#c2ffff',
    },
  },
} as Partial<ChakraTheme>);

type Theme = typeof theme;

export { theme };
export type { Theme };
