import { HStack } from '@chakra-ui/react';
import { DimensionInput } from '../DimensionInput';
import { Dimension } from '@tb/common';

export const RiseOverRunInputs = ({
  rise,
  run,
  handleRiseChange,
  handleRunChange,
}: {
  rise: Dimension;
  run: Dimension;
  handleRiseChange: (feet: number, inches: number) => void;
  handleRunChange: (feet: number, inches: number) => void;
}) => {
  return (
    <HStack>
      <DimensionInput
        handleChange={handleRiseChange}
        feet={rise.feet}
        inches={rise.inches}
      />
      <DimensionInput
        handleChange={handleRunChange}
        feet={run.feet}
        inches={run.inches}
      />
    </HStack>
  );
};
