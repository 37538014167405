import { z } from 'zod';
import { DollarAmount, QuantityResponse } from './currency';
import { TextMax512 } from './validators';

// Bid Form Response Types
export const BidFormResponseTypes = z.union([
  z.string(),
  z.boolean(),
  QuantityResponse,
  DollarAmount,
]);
export type BidFormResponseTypes = z.infer<typeof BidFormResponseTypes>;

// Bid Form Response Status
export const BidFormResponseStatus = z.enum(['TOO_HIGH', 'TOO_LOW', 'OK']);
export type BidFormResponseStatus = z.infer<typeof BidFormResponseStatus>;

// Internal Representation
export const InternalRepresentation = z.object({
  code: z.string().nullish(),
  description: TextMax512.nullish(),
});
