import { PopoverNotificationCenter } from '@novu/notification-center';
import { NotificationButton } from './NotificationButton';

export const Notification = () => {
  return (
    <PopoverNotificationCenter colorScheme="light">
      {({ unseenCount }) => <NotificationButton unread={!!unseenCount} />}
    </PopoverNotificationCenter>
  );
};
