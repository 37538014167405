import { z } from 'zod';
import { NumberOrString } from './validators';

// Select Value
export const SelectValue = z.object({
  label: z.string(),
  value: NumberOrString,
});
export type SelectValue = z.infer<typeof SelectValue>;

// Multiple options
export const SelectValues = z.array(SelectValue);
export type SelectValues = z.infer<typeof SelectValues>;
