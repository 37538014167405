import {
  PopoverContentProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Heading,
  Flex,
  PlacementWithLogical,
  HeadingProps,
  PopoverFooter,
} from '@chakra-ui/react';

export const Popup = ({
  title,
  children,
  trigger,
  contentProps,
  isOpen,
  onClose,
  placement = 'left',
  headingSize = 'md',
  footerContent,
}: {
  title?: string;
  children: React.ReactNode;
  trigger: React.ReactNode;
  contentProps?: PopoverContentProps;
  isOpen?: boolean;
  onClose?: () => void;
  placement?: PlacementWithLogical;
  headingSize?: HeadingProps['size'];
  footerContent?: React.ReactNode;
}) => {
  return (
    <Popover
      // get from useDisclosure in parent component
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      placement={placement}
      lazyBehavior="keepMounted"
      isLazy
      strategy={'absolute'}
      preventOverflow={true}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
          },
        },
      ]}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        p={4}
        shadow={'lg'}
        minW={'fit-content'}
        {...contentProps}
      >
        <PopoverArrow />
        <Flex>
          <PopoverCloseButton pt={4} />
          {!!title && (
            <PopoverHeader border="0">
              <Heading size={headingSize} pb={4}>
                {title}
              </Heading>
            </PopoverHeader>
          )}
        </Flex>
        <PopoverBody p={0}>{children}</PopoverBody>
        {!!footerContent && (
          <PopoverFooter display={'flex'} justifyContent={'flex-end'}>
            {footerContent}
          </PopoverFooter>
        )}
      </PopoverContent>
    </Popover>
  );
};
