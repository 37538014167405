import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(['table', 'th', 'td', 'caption']);

const baseStyle = (props: any) =>
  definePartsStyle({
    th: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      letterSpacing: 'normal',
      color: props.colorMode === 'dark' ? 'brand.white' : 'brand.darkGray',
    },
    td: {
      color: props.colorMode === 'dark' ? 'brand.lightGray' : 'brand.darkGray',
    },
    caption: {
      color: props.colorMode === 'dark' ? 'brand.white' : 'brand.darkGray',
    },
  });

const sizes = {
  xs: definePartsStyle({
    th: {
      px: '4',
      py: '2',
      lineHeight: '2',
      fontSize: 'xs',
    },
    td: {
      px: '4',
      py: '2',
      fontSize: 'xs',
      lineHeight: '2',
    },
    caption: {
      px: '4',
      py: '2',
      fontSize: 'xs',
    },
  }),
  sm: definePartsStyle({
    th: {
      px: '4',
      py: '2',
      lineHeight: '2',
      fontSize: 'sm',
    },
    td: {
      px: '4',
      py: '2',
      fontSize: 'sm',
      lineHeight: '2',
    },
    caption: {
      px: '4',
      py: '2',
      fontSize: 'sm',
    },
  }),
  // ... (additional sizes if needed)
};

export const Table = defineMultiStyleConfig({
  baseStyle,
  variants: {
    simple: {},
    striped: {},
    unstyled: {},
  },
  sizes,
});
