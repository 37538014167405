import { Tag, TagLabel, TagRightIcon, Tooltip } from '@chakra-ui/react';
import { IoWarningOutline } from 'react-icons/io5';
import { RiFeedbackLine } from 'react-icons/ri';

type DevStatusType = 'beta' | 'alpha' | 'stable';

const getStatusSettings = (
  status: DevStatusType,
): {
  color: string;
  label: string;
  icon?: any;
} => {
  switch (status) {
    case 'alpha':
      return {
        color: 'red',
        label: 'Alpha',
        icon: IoWarningOutline,
      };
    case 'beta':
      return {
        color: 'orange',
        label: 'Beta',
        icon: RiFeedbackLine,
      };

    default:
      return {
        color: 'gray',
        label: 'Unknown',
      };
  }
};

export const DevStatusTag = ({ status }: { status: DevStatusType }) => {
  const { color, label, icon } = getStatusSettings(status);
  const tooltipLabel = `This feature is currently in ${label}. \nPlease provide feedback`;
  return (
    <Tooltip label={tooltipLabel}>
      <Tag variant="subtle" colorScheme={color}>
        <TagLabel>{label}</TagLabel>
        {!!icon && <TagRightIcon as={icon} />}
      </Tag>
    </Tooltip>
  );
};
