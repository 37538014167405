import { Box } from '@chakra-ui/react';
import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

export const StyledErrorMessage = <T extends FieldValues>({
  errors,
  name,
}: {
  errors: FieldErrors<T>;
  name: keyof T;
}) => {
  return (
    <>
      {/* Checking for empty object to prvent padding from pushing up input */}
      {Object.keys(errors).length !== 0 && (
        <Box pt={1} color={'#E53E3E'} fontSize={'.875em'}>
          <ErrorMessage
            errors={errors}
            name={name as FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>}
          />
        </Box>
      )}
    </>
  );
};
