import { ReactNode } from 'react';
import { z } from 'zod';

// LinkDataProps
export const LinkDataProps = z.object({
  label: z.string(),
  path: z.string().optional(),
  noOverflow: z.boolean().optional(),
});
export type LinkDataProps = z.infer<typeof LinkDataProps>;

// DropDownDataProps
export const DropDownDataProps = z.object({
  label: z.string(),
  options: z.array(LinkDataProps),
  noOverflow: z.boolean().optional(),
});
export type DropDownDataProps = z.infer<typeof DropDownDataProps>;

// Breadcrumb
const Breadcrumb = z.union([LinkDataProps, DropDownDataProps]);
export type Breadcrumb = z.infer<typeof Breadcrumb>;

// Link function
export const LinkFn = z.function().returns(z.promise(z.array(LinkDataProps)));
export type LinkFn = z.infer<typeof LinkFn>;

// BreadCrumb function
export const BreadcrumbFn = z
  .function()
  .returns(z.promise(z.array(Breadcrumb)));
export type BreadcrumbFn = z.infer<typeof BreadcrumbFn>;

// Sub navbar layout config
export const SubNavLayoutConfig = z.object({
  heading: z.custom<ReactNode>().optional(),
  breadCrumbPlaceholder: z.array(Breadcrumb).optional(),
  linkPlaceholder: z.array(LinkDataProps).optional(),
  breadCrumbs: BreadcrumbFn,
  path: z.string().optional(),
  links: LinkFn,
  contextMenu: z
    .array(
      z.object({
        label: z.string(),
        onClick: z.function(),
      }),
    )
    .optional(),
});
export type SubNavLayoutConfig = z.infer<typeof SubNavLayoutConfig>;
