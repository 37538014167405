import { ComponentStyleConfig } from '@chakra-ui/react';
import * as Accordion from './accordion';
import * as Badge from './badge';
import * as BreadCrumb from './breadcrumb';
import * as Button from './button';
import * as Checkbox from './checkbox';
import * as Custom from './custom';
import { InputComponents } from './inputs';
import * as Link from './link';
import * as Table from './table';
import * as Tabs from './tabs';
import * as Text from './text';
import * as Tooltip from './tooltip';

export const components: {
  [key: string]: ComponentStyleConfig;
} = {
  ...Accordion,
  ...Button,
  ...Custom,
  ...InputComponents,
  ...Text,
  ...Link,
  ...Badge,
  ...BreadCrumb,
  ...Table,
  ...Tooltip,
  ...Checkbox,
  ...Tabs,
};
