import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AsyncSelect, Props as SelectProps } from 'chakra-react-select';
import { ReactNode, useState } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface ControlledQuerySelectProps<
  FormValues extends FieldValues = FieldValues,
  IsMulti extends boolean = boolean,
> extends Omit<SelectProps<any, IsMulti>, 'name' | 'defaultValue'>,
    UseControllerProps<FormValues> {
  label?: string;
  helpText?: ReactNode;
  orientation?: 'vertical' | 'horizontal';
  query: (inputValue: string) => Promise<any[]>;
  formControlProps?: FormControlProps;
}

export const ControlledQuerySelect = <
  FormValues extends FieldValues = FieldValues,
  IsMulti extends boolean = boolean,
>({
  name,
  helpText,
  label,
  control,
  rules,
  shouldUnregister,
  size,
  isRequired,
  chakraStyles,
  query,
  formControlProps,
  ...selectProps
}: ControlledQuerySelectProps<FormValues, IsMulti>) => {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const loadOptions = async (inputValue: string) => {
    const res = await query(inputValue);
    const options = res.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    setSelectedValue(options.find((option) => option.value === field.value));
    return options;
  };

  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <FormControl
      id={name}
      data-testid={name}
      isInvalid={!!error}
      isRequired={isRequired || false}
      {...formControlProps}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {!!label && <FormLabel fontSize={size}>{label}</FormLabel>}
        {!!helpText && (
          <Text fontSize="xs" align="right">
            {helpText}
          </Text>
        )}
      </Flex>
      <AsyncSelect
        loadOptions={loadOptions}
        cacheOptions
        defaultOptions
        menuPlacement="auto"
        placeholder="Search..."
        size={size}
        defaultValue={selectedValue}
        chakraStyles={{
          ...chakraStyles,
          control: (provided) => ({
            ...provided,
            color: textColor,
            backgroundColor: bgColor,
          }),
          placeholder: (provided) => ({ ...provided, color: textColor }),
          container: (provided) => ({ ...provided }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        {...field}
        onChange={(value, actionMeta) => {
          field.onChange(value);
          selectProps.onChange?.(value, actionMeta);
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            textColor,
            backgroundColor: bgColor,
          }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const UncontrolledQuerySelect = <
  FormValues extends FieldValues = FieldValues,
  IsMulti extends boolean = boolean,
>({
  name,
  helpText,
  label,
  size,
  isRequired,
  query,
  formControlProps,
  ...selectProps
}: ControlledQuerySelectProps<FormValues, IsMulti>) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const loadOptions = async (inputValue: string) => {
    const res = await query(inputValue);
    const options = res.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    setSelectedValue(
      options.find((option) => option.value === selectProps.value),
    );
    return options;
  };

  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <FormControl
      id={name}
      data-testid={name}
      isRequired={isRequired || false}
      {...formControlProps}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {!!label && <FormLabel fontSize={size}>{label}</FormLabel>}
        {!!helpText && (
          <Text fontSize="xs" align="right">
            {helpText}
          </Text>
        )}
      </Flex>
      <AsyncSelect
        loadOptions={loadOptions}
        cacheOptions
        defaultOptions
        menuPlacement="auto"
        placeholder="Search..."
        size={size}
        defaultValue={selectedValue}
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            color: textColor,
            backgroundColor: bgColor,
          }),
          placeholder: (provided) => ({ ...provided, color: textColor }),
          container: (provided) => ({ ...provided }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          valueContainer: (base) => ({
            ...base,
            maxHeight: 50,
            overflowY: 'auto',
          }),
        }}
        {...selectProps}
      />
    </FormControl>
  );
};
