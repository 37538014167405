import { tbLog } from '@tb/common';
import {
  TRPCClientError,
  httpBatchLink,
  httpLink,
  splitLink,
} from '@trpc/client';
import { loggerLink } from '@trpc/client/links/loggerLink';
import { createTRPCNext } from '@trpc/next';
import { inferReactQueryProcedureOptions } from '@trpc/react-query';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { NextPageContext } from 'next';
import Router from 'next/router';
import superjson from 'superjson';
import type { NewRouter } from '~/server/routers/_app';

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @usage
   * const utils = trpc.useContext();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number;
}

function getBaseUrl() {
  if (typeof window !== 'undefined')
    // browser should use relative path
    return '';

  if (process.env.VERCEL_URL)
    // reference for vercel.com
    return `https://${process.env.VERCEL_URL}`;

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

/**
 * Basic exponential backoff function. Used primarily to repeat network
 * requests at increasing lengths of time
 */
export function exponentialBackoff(attempt: number) {
  return Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);
}

type Opts = {
  skipBatch: boolean;
};

const DEFAULT_OPTS: Opts = {
  skipBatch: false,
};
/**
 * Use this as part of all useQuery hooks at the Page level.
 * These options represent sensible defaults.
 */
export function defaultQueryOpts(
  status: 'unauthenticated' | 'loading' | 'authenticated',
  opts = DEFAULT_OPTS,
) {
  return {
    enabled: status === 'authenticated',
    refetchOnWindowFocus: false,
    retryDelay: (attempt: number) => exponentialBackoff(attempt),
    retry(failureCount: number, error: any) {
      tbLog('Retrying query', { failureCount, error });
      if (pushToLoginIfUnauthorized(error)) return false;
      return failureCount < 3;
    },
    trpc: {
      context: {
        skipBatch: opts.skipBatch,
      },
    },
  };
}

export const useUtils = () => {
  return trpc.useContext();
};

// This only works if `ssr: true` is set above
export function responseMeta(opts: any) {
  const ctx = opts.ctx as SSRContext;

  if (ctx.status) {
    // If HTTP status set, propagate that
    return {
      status: ctx.status,
    };
  }

  const error = opts.clientErrors[0];
  if (error) {
    // Propagate http first error from API calls
    return {
      status: error.data?.httpStatus ?? 500,
    };
  }

  const parts = new URL(`http://localhost` + ctx.req?.url);
  if (parts.pathname === '/' || parts.pathname.startsWith('/job/')) {
    console.log('🏎 Caching:', parts.pathname);
    // cache full page for 1 day + revalidate once every second
    const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
    return {
      headers: {
        'Cache-Control': `s-maxage=300, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
      },
    };
  }

  // For app caching with SSR see https://trpc.io/docs/caching
  return {};
}

export function pushToLoginIfUnauthorized(error: unknown): boolean {
  if (typeof window === 'undefined') return false;
  if (!(error instanceof TRPCClientError)) return false;
  if (error.data?.code !== 'UNAUTHORIZED') return false;

  tbLog('User not authorized');
  Router.push('/auth/login');

  return true;
}

const trpcUrl = `${getBaseUrl()}/api/trpc`;
const MAX_URL_LENGTH = 1500;

export const trpc = createTRPCNext<NewRouter>({
  config(_opts) {
    return {
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return op.context.skipBatch === true;
          },
          // when condition is true, use normal request
          true: httpLink({
            url: trpcUrl,
          }),
          // when condition is false, use batching
          // Ensure we keep URL Length to a level acceptable by all browsers
          // https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
          false: httpBatchLink({
            url: trpcUrl,
            maxURLLength: MAX_URL_LENGTH,
          }),
        }),
      ],
      transformer: superjson,
      queryClientConfig: {
        defaultOptions: {
          // retryDelay: (attempt: number) => exponentialBackoff(attempt),
          queries: {
            retryDelay: (attempt: number) => exponentialBackoff(attempt),
            //   retry(failureCount, error) {
            //     tbLog('Retrying query', { failureCount, error });
            //     if (pushToLoginIfUnauthorized(error)) return false;
            //     return failureCount < 3;
            //   },
            // },
            // mutations: {
            //   retry: (_, error) => {
            //     tbLog('Retrying query', { error });
            //     pushToLoginIfUnauthorized(error);
            //     return false;
            //   },
          },
        },
      },
      // Ability to set request headers and HTTP status when server-side rendering
      // https://trpc.io/docs/client/nextjs/setup#responsemeta-callback

      responseMeta(_opts: any) {
        // const clientErrors = opts.clientErrors as any[];
        // tbLog('clientErrors', clientErrors);
        // if (clientErrors?.length === 0) return {};
        // const hasUnauthorizedError = clientErrors
        // .map((error: any) => error.data?.code)
        // .some((code: string) => code === 'UNAUTHORIZED');
        // if (hasUnauthorizedError) {
        // return { status: 302, headers: { Location: '/auth/login' } };
        // }
        // return {
        // status: clientErrors[0]?.data?.httpStatus ?? 500,
        // };
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});

export type ReactQueryOptions = inferReactQueryProcedureOptions<NewRouter>;
export type RouterInput = inferRouterInputs<NewRouter>;
export type RouterOutput = inferRouterOutputs<NewRouter>;
