import {
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useColorMode,
} from '@chakra-ui/react';

export interface PopupAlertProps {
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  content: React.ReactNode;
  placement?: PlacementWithLogical;
}

export const PopupAlert = ({
  isOpen,
  children,
  title,
  content,
  placement = 'bottom',
}: PopupAlertProps) => {
  const { colorMode } = useColorMode();
  return (
    <Popover placement={placement} isOpen={isOpen} closeOnBlur={false}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent
          bg={colorMode === 'light' ? 'yellow.100' : 'yellow.500'}
          borderColor={colorMode === 'light' ? 'yellow.300' : 'yellow.500'}
          p={1}
        >
          <PopoverHeader pt={3} pb={0} fontWeight="bold" border="0">
            {title}
          </PopoverHeader>
          <PopoverArrow
            bg={colorMode === 'light' ? 'yellow.100' : 'yellow.500'}
          />
          <PopoverBody>{content}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
