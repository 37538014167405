import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: 'complete.500',
  },
  variants: {
    outline: ({ colorMode }) => ({
      field: {
        backgroundColor: colorMode === 'dark' ? 'gray.900' : '#FFF',
      },
    }),
    'input-xs': ({ colorMode }) => ({
      field: {
        backgroundColor: colorMode === 'dark' ? 'darkBackground' : '#FFF',
        border:
          colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
        fontSize: 'xs',
        height: '32px',
      },
    }),
    'input-xs-underline': ({ colorMode }) => ({
      field: {
        borderBottom:
          colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #000',
        borderRadius: 0,
        fontSize: 'xs',
      },
    }),
  },
};

const Select: ComponentStyleConfig = {
  defaultProps: {
    focusBorderColor: ({ colorMode }: { colorMode: 'light' | 'dark' }) =>
      colorMode === 'dark' ? 'complete.100' : 'complete.500',
  },
  variants: {
    // 'plan-select': ({ colorMode }) => ({
    //   icon: {
    //     color: colorMode === 'dark' ? 'red' : 'red',
    //   },
    //   field: {
    //     backgroundColor: colorMode === 'dark' ? '#fff' : '#FFF',
    //     border:
    //       colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
    //     fontSize: '12px',
    //     height: '32px',
    //   },
    // }),
    'comment-select': ({ colorMode }) => ({
      field: {
        fontSize: 'xxs',
        fontWeight: 'bold',
        lineHeight: 'shorter',
        letterSpacing: 'normal',
        borderRadius: 'base',
        color: colorMode === 'dark' ? 'darkColor' : 'white',
        border: 'none',
      },
      icon: {
        color: colorMode === 'dark' ? 'darkIconColor' : '#fff',
      },
    }),
    'takeoff-select': ({ colorMode }) => ({
      field: {
        border:
          colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
        fontSize: '12px',
        height: '32px',
      },
    }),
  },
};

const Radio: ComponentStyleConfig = {
  variants: {
    primary: ({ colorMode }) => ({
      control: {
        _checked: {
          background: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          borderColor: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          _hover: {
            background: colorMode === 'dark' ? 'complete.100' : 'complete.500',
            borderColor: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          },
        },
      },
    }),
    'radio-xs': {
      label: {
        fontSize: 'xs',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    colorScheme: 'primary',
  },
};

const NumberInput: ComponentStyleConfig = {
  variants: {
    'takeoff-number-input-underline': ({ colorMode }) => ({
      field: {
        fontSize: '12px',
        borderBottom:
          colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #000',
        borderRadius: 0,
      },
      stepper: {
        fontSize: '12px',
      },
    }),
    'takeoff-number-input': ({ colorMode }) => ({
      field: {
        backgroundColor: colorMode === 'dark' ? 'darkBackground' : '#FFF',
        border:
          colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
        fontSize: 'xs',
        height: '32px',
      },
    }),
  },
};

const Textarea: ComponentStyleConfig = {
  variants: {
    primary: ({ colorMode }) => ({
      border:
        colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
      backgroundColor: colorMode === 'dark' ? 'gray.900' : '#FFF',
    }),

    'takeoff-textarea': ({ colorMode }) => ({
      border:
        colorMode === 'dark' ? '1px solid darkBorder' : '1px solid #D1D5DB',
      fontSize: '12px',
    }),
  },
};

export const InputComponents: {
  Radio: ComponentStyleConfig;
  NumberInput: ComponentStyleConfig;
  Input: ComponentStyleConfig;
  Select: ComponentStyleConfig;
  Textarea: ComponentStyleConfig;
} = {
  Radio,
  NumberInput,
  Input,
  Select,
  Textarea,
};
