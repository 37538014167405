import { Box, Checkbox, Flex, Spinner, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { ReactNode } from 'react';

export interface ThumbnailProps {
  src?: string;
  onClick?: () => void;
  selected?: boolean;
  name: string;
}

const ThumbnailContainer = (
  props: Omit<ThumbnailProps, 'src'> & {
    children: ReactNode;
  },
) => {
  const { name, selected, onClick, children } = props;

  return (
    <Box
      width="inherit"
      as="button"
      onClick={onClick}
      border="1px solid"
      borderColor={'brand.dark'}
      borderRadius="md"
      height="100%"
      display="flex"
      flexDir={'column'}
    >
      {children}

      <Box p={1} borderTop={'1px'} width="100%">
        <Flex justify={'space-between'}>
          <Text noOfLines={1}>{name}</Text>
          {!!onClick && (
            <Checkbox size="lg" isChecked={selected} onChange={onClick} />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export function ThumbnailImage(props: { src?: string }) {
  const { src } = props;
  if (!src) {
    return (
      <Flex
        bg="brand.dark"
        justify="center"
        width="100%"
        height="100%"
        align="center"
        flex={2}
      >
        <Spinner color="brand.light" />
      </Flex>
    );
  }

  return (
    <Box
      p={1}
      position={'relative'}
      width={'100%'}
      height="100%"
      bg="brand.dark"
      flex={2}
    >
      <Image
        src={src}
        layout={'fill'}
        objectFit={'contain'}
        alt="plan thumbnail"
        loading="lazy"
      />
    </Box>
  );
}

export function Thumbnail(props: ThumbnailProps) {
  const { src } = props;

  if (!src) {
    return (
      <ThumbnailContainer {...props}>
        <ThumbnailImage src={src} />
      </ThumbnailContainer>
    );
  }

  return (
    <ThumbnailContainer {...props}>
      <ThumbnailImage src={src} />
    </ThumbnailContainer>
  );
}

export default Thumbnail;
