import {
  Modal as ChakraModal,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { CancelConfirmButtons } from '../Buttons';
import { ModalModel } from './useModal';

export const Modal = ({
  model,
  modalProps,
  headerDivider,
}: {
  model: ModalModel;
  modalProps?: Pick<ModalProps, 'scrollBehavior' | 'trapFocus'>;
  headerDivider?: boolean;
}) => {
  const {
    events,
    content,
    isOpen,
    title,
    size,
    contentStyle,
    bodyProps,
    contentProps,
    footerProps,
    headerProps,
    footer,
    closeOnOverlayClick,
    showOverlay,
    closeOnEsc,
    primaryActionButton,
    handlePrimaryAction,
    secondaryActionButton,
    handleSecondaryAction,
    closeButton,
  } = model;

  // Will reimplement this as I add to other modals
  // const bodyContent = content ? (
  //   content
  // ) : (
  //   <Flex justifyContent={'center'} gap={16}>
  //     <Tooltip label={secondaryConfirmName}>
  //       <Image
  //         data-testid={testIds?.confirm}
  //         src={onConfirmIcon}
  //         boxSize={'100px'}
  //         alt="outlook"
  //         onClick={handleConfirm}
  //         cursor={'pointer'}
  //       />
  //     </Tooltip>
  //     <Tooltip label={confirmName}>
  //       <Image
  //         data-testid={testIds?.secondaryConfirm}
  //         src={onSecondaryConfirmIcon}
  //         boxSize={'100px'}
  //         alt="mail"
  //         onClick={handleSecondaryConfirm}
  //         cursor={'pointer'}
  //       />
  //     </Tooltip>
  //   </Flex>
  // );

  // const colorModeBg = useColorModeValue('complete.500', 'complete.100');
  // const colorModeHoverBg = useColorModeValue('complete.700', 'complete.500');

  // const customColorModeBg = useColorModeValue(
  //   `${confirmColor}.500`,
  //   `${confirmColor}.100`,
  // );
  // const customColorModeHoverBg = useColorModeValue(
  //   `${confirmColor}.700`,
  //   `${confirmColor}.500`,
  // );

  // const confirmBgColor = useMemo(
  //   () => (confirmColor === undefined ? colorModeBg : customColorModeBg),
  //   [confirmColor, colorModeBg, customColorModeBg],
  // );

  // const confirmHoverBgColor = useMemo(
  //   () =>
  //     confirmColor === undefined ? colorModeHoverBg : customColorModeHoverBg,
  //   [confirmColor, colorModeHoverBg, customColorModeHoverBg],
  // );

  const primaryActionButtonProps =
    primaryActionButton?.onClick && handlePrimaryAction
      ? { ...primaryActionButton, onClick: handlePrimaryAction }
      : undefined;

  const secondaryActionButtonProps =
    secondaryActionButton?.onClick && handleSecondaryAction
      ? {
          ...secondaryActionButton,
          onClick: handleSecondaryAction,
        }
      : undefined;

  const cancelButtonProps =
    closeButton?.text && events.close
      ? {
          ...closeButton,
          onClick: events.close,
        }
      : undefined;

  const getFooter = () => {
    if (footer) return footer;

    if (primaryActionButton || secondaryActionButton || closeButton) {
      return (
        <ModalFooter gap={3} {...footerProps}>
          <CancelConfirmButtons
            primaryActionButton={primaryActionButtonProps}
            secondaryActionButton={secondaryActionButtonProps}
            cancelButton={cancelButtonProps}
          />
        </ModalFooter>
      );
    }
  };

  if (!isOpen) return null;

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={events.close}
      isCentered
      closeOnEsc={closeOnEsc}
      returnFocusOnClose={false}
      closeOnOverlayClick={closeOnOverlayClick}
      size={size}
      {...modalProps}
    >
      {!!showOverlay && <ModalOverlay />}
      <ModalContent style={contentStyle} className="tb-modal" {...contentProps}>
        <ModalHeader {...headerProps}>
          {title}
          {!!headerDivider && <Divider />}
        </ModalHeader>
        <ModalCloseButton data-testid="modal-close-btn" />
        <ModalBody {...bodyProps}>{content}</ModalBody>
        {getFooter()}
      </ModalContent>
    </ChakraModal>
  );
};
