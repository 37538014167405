import Script from 'next/script';
import { trpc } from '~/utils/trpc';

const BASE_URL = 'https://app.chatwoot.com';

declare global {
  interface Window {
    chatwootSettings: {
      hideMessageBubble: boolean;
      position: 'left' | 'right';
      locale: string;
      type: 'standard' | 'expanded_bubble';
    };
    chatwootSDK: {
      run: (params: { websiteToken: string; baseUrl: string }) => void;
    };
  }
}

export const ChatwootScript = () => {
  const { data, isLoading } = trpc.chatwoot.getToken.useQuery(undefined, {
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });

  if (isLoading || !data) {
    return null;
  }

  const src = `${BASE_URL}/packs/js/sdk.js`;
  const onLoad = () => {
    window.chatwootSettings = {
      hideMessageBubble: true,
      position: 'left',
      locale: 'en',
      type: 'expanded_bubble',
    };

    window.chatwootSDK.run({
      websiteToken: data,
      baseUrl: BASE_URL,
    });
  };

  return <Script src={src} onLoad={onLoad} />;
};
