import { z } from 'zod';
import { DBECertificate } from './DBECertificate';
import { Bid, BidStatus } from './bid';
import { CsiCode, Trade } from './csi';
import { EnterpriseType } from './enterpriseType';
import { BidId, BidderId, SubContractorId } from './ids';
import {
  LaborTypes,
  PreferredStatusTypes,
  QualificationStatus,
  SubContractorMember,
} from './subContractor';

// Bidding Subcontractor
export const BiddingSubcontractor = z.object({
  id: SubContractorId,
  name: z.string().optional(),
  location: z.string().optional(),
  preferredStatus: PreferredStatusTypes.optional(),
  qualificationStatus: QualificationStatus,
  trades: z.array(Trade).default([]),
  csiCodes: z.array(CsiCode).default([]),
  laborTypes: z.array(LaborTypes).optional(),
  dbeCertificates: z.array(DBECertificate).optional(),
  enterpriseTypes: z.array(EnterpriseType).optional(),
});
export type BiddingSubcontractor = z.infer<typeof BiddingSubcontractor>;

// Bidder Status Input
export const BidderStatusInput = z.object({
  bidId: BidId,
  status: BidStatus,
});
export type BidderStatusInput = z.infer<typeof BidderStatusInput>;

// Bidder
export const Bidder = z.object({
  id: BidderId,
  bid: Bid.pick({ id: true, name: true, cost: true }),
  status: BidStatus,
  subcontractor: BiddingSubcontractor,
  stakeholders: z.array(SubContractorMember),
  viewedAt: z.date().optional(),
  biddingAt: z.date().optional(),
  confirmedAt: z.date().optional(),
  submittedAt: z.date().optional(),
});
export type Bidder = z.infer<typeof Bidder>;
