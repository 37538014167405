import { z } from 'zod';

// TODO Get rid of this, why!?
export const WholeNumber = z.number().gt(0);

// Text validators
export const TextMax255 = z.string().max(255);
export type TextMax255 = z.infer<typeof TextMax255>;

export const TextMax512 = z.string().min(1).max(512);
export type TextMax512 = z.infer<typeof TextMax512>;

export const TextMax4096 = z.string().min(1).max(4096);
export type TextMax4096 = z.infer<typeof TextMax4096>;

export const NumberOrString = z.union([z.string(), z.number()]);
export type NumberOrString = z.infer<typeof NumberOrString>;

export const passwordValidator = z
  .string({ required_error: 'Password is required' })
  .min(8, 'Password must be at least 8 characters long')
  .max(255, 'Password must be less than 255 characters long')
  .regex(
    new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&;*])'),
    ' Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character (!@#$%^&*;)',
  );

// Project Validators
export const ProjectName = TextMax255;
export const EstimatorName = TextMax255;

// CSI code validator
export const CSISectionName = TextMax255;

// Enterprise type name validator
export const EnterpriseTypeName = TextMax512;

// DBECertificates
export const CertificateName = TextMax512;
