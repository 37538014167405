import { z } from 'zod';

// Tag
export const Tag = z.object({
  value: z.string(),
  label: z.string(),
});
export type Tag = z.infer<typeof Tag>;

// Tag Select
export const TagSelect = z.object({
  value: z.string(),
  label: z.string(),
});
export type TagSelect = z.infer<typeof TagSelect>;
