import { z } from 'zod';
import { DBECertificateId } from './ids';
import { SelectValue } from './options';
import { CertificateName } from './validators';

// DBE Certificates - Disadvantaged Business Enterprise
// Minority or Women-owned Businesses
export const DBECertificate = z.object({
  id: DBECertificateId,
  name: CertificateName,
});
export type DBECertificate = z.infer<typeof DBECertificate>;

// DBE Certificate Options
export const DBECertSelect = SelectValue;
export type DBECertSelect = z.infer<typeof DBECertSelect>;
