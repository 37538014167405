import chalk from 'chalk';
import pino, { Logger as PinoLogger } from 'pino';

const loggerFactory = (): Console | undefined => {
  return process.env.NODE_ENV === 'production' ? undefined : console;
};

const logger = loggerFactory();

const tbLog = (name: string, ...a: unknown[]) => {
  logger?.log(chalk.bold.greenBright.bgBlack(' ' + name + ' '), ...a);
};

const tbWarn = (name: string, ...a: unknown[]) => {
  logger?.warn(chalk.bold.yellowBright(' ' + name + ' '), ...a);
};

const tbError = (name: string, ...a: unknown[]) => {
  logger?.error(chalk.bold.yellow.bgBlack(' ' + name + ' '), ...a);
};

export { tbLog, tbWarn, tbError };

// create pino logger
export const Logger: PinoLogger = pino();
