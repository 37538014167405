import { ComponentStyleConfig } from '@chakra-ui/react';

export const Breadcrumb: ComponentStyleConfig = {
  variants: {
    'sub-nav': ({ colorMode }) => ({
      link: {
        _hover: {
          textDecoration: 'none',
        },
      },
      item: {
        color: colorMode === 'dark' ? 'darkBrand.darkGray' : 'brand.darkGray',
        bg: 'transparent',
        border: 'none',
        fontWeight: '700',
        fontSize: 'md',
        lineHeight: 'base',
        _hover: {
          textDecoration: 'none',
        },
        _active: {
          fontWeight: 'bold',
        },
      },
    }),
  },
};
