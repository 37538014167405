import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputProps,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { HTMLInputTypeAttribute } from 'react';
import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegisterReturn,
} from 'react-hook-form';
import { StyledErrorMessage } from './StyledErrorMessage';

type Props<T extends FieldValues> = {
  label: string;
  name: Path<T>;
  testId?: string;
  isRequired?: boolean;
  errors: FieldErrors<T>;
  register: () => UseFormRegisterReturn;
  type: HTMLInputTypeAttribute | 'textarea';
  variant?: string;
  size?: InputProps['size'];
  isDisabled?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  autoFocus?: boolean;
} & FormControlProps;

export const StandardFormInput = <T extends FieldValues>({
  label,
  name,
  testId,
  isRequired,
  errors,
  register,
  type,
  variant,
  isDisabled,
  size = 'md',
  textAlign,
  autoFocus,
  ...formControlProps
}: Props<T>) => {
  const isInvalid = name in errors;
  const dataTestId = testId || `${name}-input`;
  const bgColor = useColorModeValue('white', 'gray.900');

  return (
    <FormControl isRequired={isRequired || false} {...formControlProps}>
      <FormLabel fontSize={size}>{label}</FormLabel>
      {type === 'textarea' ? (
        <Textarea
          size={size}
          isInvalid={isInvalid}
          data-testid={dataTestId}
          {...register()}
          variant={variant}
          disabled={isDisabled || false}
          autoFocus={autoFocus}
        />
      ) : (
        <Input
          size={size}
          isInvalid={isInvalid}
          data-testid={dataTestId}
          autoComplete="off"
          type={type}
          {...register()}
          variant={variant}
          disabled={isDisabled || false}
          bg={bgColor}
          textAlign={textAlign ?? 'left'}
          autoFocus={autoFocus}
        />
      )}

      <StyledErrorMessage errors={errors} name={name} />
    </FormControl>
  );
};
