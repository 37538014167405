import {
  Flex,
  FlexProps,
  Icon,
  Text,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { MdAddCircleOutline } from 'react-icons/md';

export const AddLineItemButton: FunctionComponent<FlexProps> = forwardRef<
  FlexProps,
  'div'
>((props: FlexProps, ref) => {
  const colorModeColor = useColorModeValue('complete.500', 'complete.100');
  return (
    <Flex w={'100%'} ref={ref} alignItems={'center'} justifyContent={'center'}>
      <Flex
        gap={2}
        alignItems={'center'}
        justifyContent={'center'}
        border={'1px'}
        borderStyle={'dashed'}
        borderColor={colorModeColor}
        borderRadius={'md'}
        p={'15px 20px'}
        mt={4}
        cursor={'pointer'}
        w={'100%'}
        {...props}
      >
        <Icon as={MdAddCircleOutline} color={colorModeColor} />
        <Text variant={'body-sm-bold'} color={colorModeColor}>
          {props.children}
        </Text>
      </Flex>
    </Flex>
  );
});
