import { useState } from 'react';

/**
 * Returns the debounced value and a function to set the debounced value
 * @param {T} initialValue - initial value of the debounced state
 * @param {number} delay - delay in milliseconds
 * @template T - type of the state value to be debounced (accepts string subsets or any object with string keys) (e.g. string, UserDefinedType)
 * @example
 * const { debounceValue: myValue, setDebounceValue: setMyValue } = useDebouncedState({
 *    initialValue: 'initial value',
 *    delay: 1000,
 *    onDebounce: (value) => {
 *      console.log('Hello world', value);
 *    }
 * });
 *
 * setMyValue('new value');
 * // the console.log defined in onDebounce event will be called after 1000ms with value 'new value'
 *
 */
export const useDebouncedState = <
  T extends number | boolean | string | Record<string, unknown> | undefined,
>(
  initialValue: T,
  delay: number,
  onDebounce: (value: T) => void | Promise<void>,
  options: {
    /**
     * guard function to prevent debouncing based defined conditions (e.g. only debounce if value is a string and has length > 3)
     * @param value - value to be debounced
     * @returns {boolean} - true if debounce should be triggered, false otherwise
     */
    shouldDebounce?: (value: T) => boolean;
  } = {},
) => {
  const [debounceValue, setDebounceValue] = useState<T>(initialValue);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const { shouldDebounce } = options;

  // debounce setter function
  const _setDebounceValue = (value: T) => {
    setDebounceValue(value);

    if (timer) {
      clearTimeout(timer);
    }

    // if no guard function is defined or guard function returns true, debounce the value
    if (!shouldDebounce || shouldDebounce?.(value)) {
      setTimer(
        setTimeout(() => {
          onDebounce(value);
        }, delay),
      );
    }
  };

  return {
    debounceValue,
    // debounce setter function
    setDebounceValue: _setDebounceValue,
    // override the debounced value without debouncing
    overrideStateValue: setDebounceValue,
  };
};
