import { ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  baseStyle: (props) => ({
    lineHeight: 'base',
    color: props.colorMode === 'dark' ? 'white' : 'black',
  }),
  variants: {
    'h4-bold': {
      fontSize: '3xl',
      fontWeight: 'bold',
      lineHeight: '11',
    },
    'sub-section-header': {
      fontWeight: 'normal',
      fontSize: '2xl',
      lineHeight: '11',
    },
    'sub-header-bold': {
      fontWeight: 'bold',
      fontSize: '2xl',
      lineHeight: '11',
    },
    'sub-header': {
      fontWeight: 'normal',
      fontSize: '2xl',
      lineHeight: '8',
    },
    subtitle: {
      fontSize: 'xl',
      lineHeight: 'shorter',
      fontWeight: 'normal',
    },
    'subtitle-bold': ({ colorMode }) => ({
      fontSize: 'xl',
      lineHeight: 'shorter',
      fontWeight: 'bold',
      color: colorMode === 'dark' ? 'brand.white' : 'brand.black',
    }),
    'subtitle-muted': ({ colorMode }) => ({
      fontSize: 'xl',
      lineHeight: 'shorter',
      fontWeight: 'normal',
      color: colorMode === 'light' ? 'gray.600' : 'white',
    }),
    body: {
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'normal',
    },
    'body-lg': {
      fontSize: 'lg',
      lineHeight: '6',
      fontWeight: '500',
    },
    'body-xl': {
      fontSize: 'xl',
      lineHeight: '7',
      fontWeight: 'normal',
    },
    'body-muted': ({ colorMode }) => ({
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'normal',
      color: colorMode === 'light' ? 'gray.600' : 'white',
    }),
    'body-bold': {
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'bold',
    },
    'body-bold-muted': ({ colorMode }) => ({
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'bold',
      color: colorMode === 'light' ? 'gray.600' : 'white',
    }),
    'body-bold-white': {
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'bold',
      color: 'white',
    },
    'body-sm': {
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'normal',
    },
    'body-sm-bold': {
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'bold',
    },
    'body-xs-bold': {
      fontSize: 'xs',
      lineHeight: 'base',
      fontWeight: 'bold',
    },
    'body-sm-muted': ({ colorMode }) => ({
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'normal',
      color: colorMode === 'light' ? 'gray.600' : 'white',
    }),
    'body-xs': {
      fontSize: 'xs',
      lineHeight: 'base',
      fontWeight: 'normal',
    },
    'body-xs-muted': ({ colorMode }) => ({
      fontSize: 'xs',
      lineHeight: 'base',
      fontWeight: 'normal',
      color: colorMode === 'dark' ? 'white' : 'gray.600',
    }),
    'login-xs-muted': ({ colorMode }) => ({
      fontSize: 'xs',
      lineHeight: 'base',
      fontWeight: 'bold',
      color: colorMode === 'light' ? 'gray.600' : 'white',
      textDecoration: 'underline',
    }),
    'data-label': ({ colorMode }) => ({
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 'xs',
      lineHeight: '3',
      color: colorMode === 'light' ? 'gray.600' : 'white',
      textTransform: 'uppercase',
    }),
    'mono-spaced': {
      fontFamily: 'mono',
      lineHeight: 'base',
      fontWeight: 'normal',
    },
  },
};

export const FormLabel: ComponentStyleConfig = {
  variants: {
    login: ({ colorMode }) => ({
      fontSize: 'md',
      lineHeight: 'base',
      fontWeight: 'normal',
      color: colorMode === 'dark' ? 'brand.white' : 'brand.black',
    }),
    'label-sm-bold': ({ colorMode }) => ({
      fontSize: 'sm',
      lineHeight: 'base',
      fontWeight: 'bold',
      color: colorMode === 'dark' ? 'brand.white' : 'brand.black',
    }),
  },
};

export const List: ComponentStyleConfig = {
  variants: {
    'body-sm': ({ colorMode }) => ({
      item: {
        fontSize: 'sm',
        lineHeight: 'base',
        fontWeight: 'normal',
        color: colorMode === 'dark' ? 'brand.white' : 'brand.black',
      },
    }),
  },
};
