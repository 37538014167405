import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  InputProps,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface ControlledCheckboxProps<FormValues extends FieldValues = FieldValues>
  extends UseControllerProps<FormValues> {
  label?: string;

  isRequired?: boolean;
  size: InputProps['size'];
  isDisabled?: boolean;
}

export const ControlledCheckbox = <
  FormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  shouldUnregister,
  size,
  isRequired,
  isDisabled,
}: ControlledCheckboxProps<FormValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const handleChange = (_e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(!field.value);
  };
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      <Checkbox
        size={size}
        isChecked={field.value}
        onChange={handleChange}
        isDisabled={isDisabled}
      >
        {label}
      </Checkbox>

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};
