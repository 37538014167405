import {
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ModalExpandButton } from './ModalExpandButton';
import { DialogModel } from './useDialog';

export const Dialog = ({
  model,
  modalProps,
  headerDivider,
  confirmName = 'Confirm',
  secondaryConfirmName,
  cancelName = 'Cancel',
  showCancel = true,
}: {
  model: DialogModel;
  modalProps?: Pick<ModalProps, 'scrollBehavior' | 'trapFocus'>;
  headerDivider?: boolean;
  confirmName?: string;
  secondaryConfirmName?: string;
  cancelName?: string;
  showCancel?: boolean;
}) => {
  const {
    content,
    handleClose,
    handleConfirm,
    handleSecondaryConfirm,
    isOpen,
    title,
    size,
    allowResize,
    testIds,
    contentStyle,
    bodyProps,
    contentProps,
    footerProps,
    headerProps,
    buttonSize,
    footer,
    onConfirmIcon,
    onSecondaryConfirmIcon,
    confirmColor,
    submitDisabled,
    closeOnOverlayClick,
    showOverlay,
    closeOnEsc,
  } = model;

  const [localSize, setLocalSize] = useState(size);
  useEffect(() => {
    if (size) {
      setLocalSize(size);
    }
  }, [size]);

  const bodyContent = content ? (
    content
  ) : (
    <Flex justifyContent={'center'} gap={16}>
      <Tooltip label={secondaryConfirmName}>
        <Image
          data-testid={testIds?.confirm}
          src={onConfirmIcon}
          boxSize={'100px'}
          alt="outlook"
          onClick={handleConfirm}
          cursor={'pointer'}
        />
      </Tooltip>
      <Tooltip label={confirmName}>
        <Image
          data-testid={testIds?.secondaryConfirm}
          src={onSecondaryConfirmIcon}
          boxSize={'100px'}
          alt="mail"
          onClick={handleSecondaryConfirm}
          cursor={'pointer'}
        />
      </Tooltip>
    </Flex>
  );

  const colorModeBg = useColorModeValue(
    `${confirmColor || 'complete'}.500`,
    `${confirmColor || 'complete'}.100`,
  );
  const colorModeHoverBg = useColorModeValue(
    `${confirmColor || 'complete'}.700`,
    `${confirmColor || 'complete'}.500`,
  );

  if (!isOpen) return null;

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      closeOnEsc={closeOnEsc}
      returnFocusOnClose={false}
      closeOnOverlayClick={closeOnOverlayClick}
      size={localSize}
      trapFocus={false}
    >
      {!!showOverlay && <ModalOverlay />}
      <ModalContent style={contentStyle} className="tb-modal" {...contentProps}>
        <ModalHeader {...headerProps}>
          {title}
          {!!headerDivider && <Divider />}
        </ModalHeader>
        {!!allowResize && (
          <ModalExpandButton
            setSize={setLocalSize}
            initialSize={size}
            currentSize={localSize}
          />
        )}

        <ModalCloseButton data-testid="modal-close-btn" />
        <ModalBody {...bodyProps}>{bodyContent}</ModalBody>
        {footer !== undefined ? (
          footer
        ) : (
          <ModalFooter gap={3} {...footerProps}>
            {!!showCancel && (
              <Button
                variant={'outline'}
                onClick={handleClose}
                data-testid={testIds?.cancel}
                size={buttonSize}
              >
                {cancelName}
              </Button>
            )}
            {!!handleSecondaryConfirm && !onSecondaryConfirmIcon && (
              <Button
                variant={'primary'}
                onClick={handleSecondaryConfirm}
                size={buttonSize}
                data-testid={testIds?.secondaryConfirm}
              >
                {secondaryConfirmName}
              </Button>
            )}
            {!onConfirmIcon && (
              <Button
                variant={'primary'}
                backgroundColor={colorModeBg}
                _hover={{
                  background: colorModeHoverBg,
                }}
                onClick={handleConfirm}
                data-testid={testIds?.confirm}
                size={buttonSize}
                isDisabled={submitDisabled}
              >
                {confirmName}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
