import {
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ThemingProps,
} from '@chakra-ui/react';
import { useState } from 'react';

export type DialogConfig = {
  open?: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element;
  onConfirm?: () => void;
  secondaryOnConfirm?: () => void;
  onCancel?: () => void;
  size?: ThemingProps<'Modal'>['size'];
  testIds?: {
    confirm?: string;
    secondaryConfirm?: string;
    cancel?: string;
  };
  contentStyle?: React.CSSProperties;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  footerProps?: ModalFooterProps;
  headerProps?: ModalHeaderProps;
  buttonSize?: ThemingProps<'Button'>['size'];
  footer?: string | JSX.Element | null;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirmIcon?: string;
  onSecondaryConfirmIcon?: string;
  cancelColor?: 'red' | 'green';
  confirmColor?: string;
  submitDisabled?: boolean;
  closeOnOverlayClick?: boolean;
  showOverlay?: boolean;
  closeOnEsc?: boolean;
  allowResize?: boolean;
};

export type DialogModel = {
  isOpen: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element;
  handleConfirm: () => void;
  handleSecondaryConfirm?: () => void;
  handleClose: () => void;
  events: {
    open: () => void;
    close: () => void;
  };
  size?: ThemingProps<'Modal'>['size'];
  testIds?: {
    confirm?: string;
    secondaryConfirm?: string;
    cancel?: string;
  };
  contentStyle?: React.CSSProperties;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  footerProps?: ModalFooterProps;
  headerProps?: ModalHeaderProps;
  buttonSize?: ThemingProps<'Button'>['size'];
  footer?: string | JSX.Element | null;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirmIcon?: string;
  onSecondaryConfirmIcon?: string;
  confirmColor?: string;
  cancelColor?: 'red' | 'green';
  submitDisabled?: boolean;
  closeOnOverlayClick?: boolean;
  showOverlay?: boolean;
  closeOnEsc?: boolean;
  allowResize: boolean;
};

export const useDialog = ({
  content,
  onCancel,
  onConfirm,
  secondaryOnConfirm,
  open = false,
  title,
  size,
  testIds,
  contentStyle,
  bodyProps,
  contentProps,
  footerProps,
  headerProps,
  buttonSize,
  footer,
  onOpen,
  onClose,
  onConfirmIcon,
  onSecondaryConfirmIcon,
  cancelColor = 'green',
  confirmColor = undefined,
  submitDisabled = false,
  closeOnOverlayClick = true,
  showOverlay = true,
  closeOnEsc = true,
  allowResize = false,
}: DialogConfig): DialogModel => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClose = () => {
    setIsOpen(false);
    onCancel?.();
    onClose?.();
  };

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm?.();
  };

  const handleSecondaryConfirm = secondaryOnConfirm
    ? () => {
        setIsOpen(false);
        secondaryOnConfirm?.();
        onClose?.();
      }
    : undefined;

  return {
    isOpen,
    handleClose,
    handleSecondaryConfirm,
    handleConfirm,
    title,
    content,
    events: {
      open: () => {
        setIsOpen(true);
        onOpen?.();
      },
      close: handleClose,
    },
    size,
    testIds,
    contentStyle,
    bodyProps,
    contentProps,
    footerProps,
    headerProps,
    buttonSize,
    footer,
    onConfirmIcon,
    onSecondaryConfirmIcon,
    cancelColor,
    confirmColor,
    submitDisabled,
    closeOnOverlayClick,
    showOverlay,
    closeOnEsc,
    allowResize,
  };
};
