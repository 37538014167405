import { z } from 'zod';
import {
  BidFormResponseStatus,
  BidFormResponseTypes,
} from './bid-forms-common';
import { BidLevelEvent } from './bid-forms-events';
import { BidFormTemplateQuestion } from './bid-templates';
import { DollarAmount } from './currency';
import { BidFormResponseId, BidderId, IDNumber } from './ids';
import { TextMax255, TextMax512, WholeNumber } from './validators';

// Bid form response
export const BidFormResponseQuestion = z.object({
  id: IDNumber,
  question: BidFormTemplateQuestion,
  response: BidFormResponseTypes,
  leveledResponse: BidFormResponseTypes.optional(), // pulled from latest bid level event
  leveledNotes: TextMax512.optional(), // represents the notes made by the GC when leveling the response (pulled from latest bid level event)
  notes: TextMax512.optional(),
  status: BidFormResponseStatus.default('OK'),
  total: DollarAmount.default({ amount: 0, currency: 'USD' }),
  leveledCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  isTemplateResponse: z.boolean().default(false).optional(),
  sectionId: IDNumber.optional(),
  levelingEvents: z.array(BidLevelEvent).optional(),
  awarded: z.boolean().default(false).optional(),
});
export type BidFormResponseQuestion = z.infer<typeof BidFormResponseQuestion>;

// Bid form response section
export const BidFormResponseSection = z.object({
  id: IDNumber,
  templateSectionId: IDNumber,
  title: TextMax255,
  orderId: WholeNumber,
  questionResponses: z.array(BidFormResponseQuestion),
  totalCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  maxRows: WholeNumber.optional(),
});
export type BidFormResponseSection = z.infer<typeof BidFormResponseSection>;

// Bid form response
export const BidFormResponse = z.object({
  id: BidFormResponseId,
  bidFormResponseId: IDNumber,
  submitted: z.boolean().default(false),
  version: WholeNumber,
  name: TextMax255,
  baseCost: DollarAmount.default({ amount: 0, currency: 'USD' }),
  leveledCost: DollarAmount.default({ amount: 0, currency: 'USD' }),
  updatedAt: z.date(),
  submittedAt: z.date().optional().nullable(),
  sections: z.array(BidFormResponseSection),
  status: BidFormResponseStatus.default('OK'),
  rank: z.number().optional(),
  bidderId: BidderId.optional(),
});
export type BidFormResponse = z.infer<typeof BidFormResponse>;

// Estimated Bid Form
const EstimatedBidForm = z.object({
  id: IDNumber,
  version: WholeNumber,
  name: TextMax255,
  baseCost: DollarAmount.default({ amount: 0, currency: 'USD' }),
  updatedAt: z.date(),
  sections: z.array(BidFormResponseSection),
});
export type EstimatedBidForm = z.infer<typeof EstimatedBidForm>;

// Order Input Item
export const OrderInputItem = z.object({
  id: IDNumber,
  orderId: IDNumber,
});
export type OrderInputItem = z.infer<typeof OrderInputItem>;

// Order Input
export const OrderInput = z.object({
  items: z.array(OrderInputItem),
});
export type OrderInput = z.infer<typeof OrderInput>;
