import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBoolean,
} from '@chakra-ui/react';
import { createRef } from 'react';
import { MdFullscreen } from 'react-icons/md';
import { Card, CardProps } from '../Card';
export interface ExpandableCardProps extends CardProps {
  children: React.ReactNode;
}

export const ExpandableCard = ({ children, ...props }: ExpandableCardProps) => {
  const [open, setOpen] = useBoolean();
  const cardRef = createRef<HTMLDivElement>();
  // const dimensions = useSize(cardRef);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { variant, ...rest } = props;

  return (
    <>
      <Card
        ref={cardRef}
        overflow={'hidden'}
        {...rest}
        style={{
          position: 'relative',
          paddingTop: '50px',
        }}
      >
        <IconButton
          zIndex={9}
          position="relative"
          bgColor={'transparent'}
          // transform={`translate(${width - 60}px, -${30}px)`}
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 0,
          }}
          variant={'action-icon'}
          borderColor="transparent"
          fontSize="24px"
          transitionProperty={
            'background-color,border-color,color,fill,stroke,opacity,box-shadow'
          }
          icon={<MdFullscreen />}
          aria-label="expand"
          onClick={setOpen.on}
        />

        {children}
      </Card>
      <Modal onClose={setOpen.off} size={'full'} isOpen={open}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody h={'100%'}>
            <Box height="90vh">{children}</Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
