import {
  Flex,
  HStack,
  InputProps,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';

export interface DimensionInputProps extends InputProps {
  handleChange: (feet: number, inches: number) => void;
  label?: string;
  icon?: IconType;
  showLabel?: boolean;
  feet: number;
  inches: number;
}

export const DimensionInput = (props: DimensionInputProps) => {
  const { handleChange, feet, inches } = props;

  const handleFeetChange = (_numString: string, numVal: number) => {
    handleChange(numVal, inches);
  };

  const handleInchesChange = (_numString: string, numVal: number) => {
    handleChange(feet, numVal);
  };

  return (
    <HStack>
      <Flex flexDirection={'row'} gap={1}>
        <NumberInput
          size={'xs'}
          p={0}
          min={0}
          step={1}
          inputMode="numeric"
          onChange={handleFeetChange}
          format={(val: string | number) => `${val}'`}
          defaultValue={feet}
          sx={{
            '& input': {
              paddingRight: 0,
            },
          }}
        >
          <NumberInputField pl={1} />
        </NumberInput>
        <NumberInput
          size={'xs'}
          p={0}
          min={0.0}
          step={0.1}
          max={12}
          inputMode="decimal"
          precision={2}
          onChange={handleInchesChange}
          defaultValue={inches}
          format={(val: string | number) => `${val}"`}
          sx={{
            '& input': {
              paddingRight: 0,
            },
          }}
        >
          <NumberInputField pl={1} />
        </NumberInput>
      </Flex>
    </HStack>
  );
};
