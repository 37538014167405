import {
  CloseButton,
  CloseButtonProps,
  Icon,
  ModalProps,
  forwardRef,
  useModalStyles,
} from '@chakra-ui/react';
import { MdCloseFullscreen, MdFullscreen } from 'react-icons/md';

type Props = Omit<CloseButtonProps, 'onClick'> & {
  expanded?: boolean;
  initialSize: ModalProps['size'];
  currentSize: ModalProps['size'];
  setSize: (size: ModalProps['size']) => void;
};

export const ModalExpandButton = forwardRef<Props, 'button'>((props, ref) => {
  const { className, initialSize, currentSize, setSize, ...rest } = props;
  const styles = useModalStyles();

  const expanded = currentSize === 'full';

  const onClick = () => {
    if (currentSize === 'full') {
      if (initialSize === 'full') {
        setSize('2xl');
      } else {
        setSize(initialSize);
      }
    } else {
      setSize('full');
    }
  };

  return (
    <CloseButton
      ref={ref}
      __css={{
        ...styles.closeButton,
        right: '50px',
      }}
      className={`chakra-modal__close-btn ${className}`}
      onClick={onClick}
      {...rest}
    >
      {expanded ? (
        <Icon as={MdCloseFullscreen} fontSize="lg" />
      ) : (
        <Icon as={MdFullscreen} fontSize="xl" />
      )}
    </CloseButton>
  );
});
