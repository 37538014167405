import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  CreatableSelect,
  GroupBase,
  Select,
  Props as SelectProps,
} from 'chakra-react-select';
import { ReactNode } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface ControlledSelectProps<
  FormValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<SelectProps<Option, IsMulti, Group>, 'name' | 'defaultValue'>,
    UseControllerProps<FormValues> {
  label?: string;
  helpText?: ReactNode;
  orientation?: 'vertical' | 'horizontal';
  flex?: number;
}

export const formatSimpleItem = <
  T extends { id: string | number; name: string },
>(
  item: T,
): {
  label: string;
  value: T['id'];
} => {
  return {
    label: item.name,
    value: item.id,
  };
};

/**
 * An attempt to make a reusable chakra-react-select form component
 *
 * @param props - The combined props of the chakra-react-select component and the useController hook
 */
export function ControlledSelect<
  FormValues extends FieldValues = FieldValues,
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  helpText,
  label,
  options,
  control,
  rules,
  shouldUnregister,
  creatable = true,
  size,
  isRequired,
  chakraStyles,
  orientation = 'vertical',
  flex,
  ...selectProps
}: ControlledSelectProps<FormValues, Option, IsMulti, Group> & {
  creatable?: boolean;
}) {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  // useEffect(() => {
  //   tbError(error);
  // }, [error]);

  return (
    <FormControl
      id={name}
      data-testid={name}
      isInvalid={!!error}
      isRequired={isRequired || false}
      flex={flex}
      {...(orientation === 'horizontal' && { display: 'flex' })}
      {...(orientation === 'horizontal' && { alignItems: 'center' })}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {!!label && <FormLabel fontSize={size}>{label}</FormLabel>}
        {!!helpText && (
          <Text fontSize="xs" align="right">
            {helpText}
          </Text>
        )}
      </Flex>

      {creatable ? (
        <CreatableSelect<Option, IsMulti, Group>
          size={size}
          chakraStyles={{
            ...chakraStyles,
            control: (provided) => ({
              ...provided,
              color: textColor,
              backgroundColor: bgColor,
            }),
            placeholder: (provided) => ({
              ...provided,
              color: textColor,
            }),
            container: (provided) => ({
              ...provided,
            }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          options={options}
          menuPlacement="auto"
          {...selectProps}
          {...{
            ...field,
            onChange: (value, actionMeta) => {
              field.onChange(value);
              selectProps.onChange?.(value, actionMeta);
            },
          }}
        />
      ) : (
        <Select<Option, IsMulti, Group>
          options={options}
          menuPlacement="auto"
          size={size}
          {...selectProps}
          {...{
            ...field,
            onChange: (value, actionMeta) => {
              field.onChange(value);
              selectProps.onChange?.(value, actionMeta);
            },
          }}
          styles={{
            control: (provided) => ({
              ...provided,
              textColor,
              background: bgColor,
            }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      )}

      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}
