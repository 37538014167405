import { ComponentStyleConfig } from '@chakra-ui/react';

export const Accordion: ComponentStyleConfig = {
  variants: {
    'no-horizontal-padding': {
      button: {
        webkitPaddingStart: '5px',
        paddingInlineStart: '5px',
        webkitPaddingEnd: '5px',
        paddingInlineEnd: '5px',
      },
    },
  },
};
