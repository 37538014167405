import {
  Flex,
  HStack,
  Icon,
  Text,
  FlexProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

type DetailsItemProps = FlexProps & {
  label?: string;
  value?: string | number | undefined | React.ReactNode;
  icon?: IconType;
  fallback?: string;
};

export const DetailsItem = ({
  label,
  value,
  fallback = 'N/A',
  icon,
  ...rest
}: DetailsItemProps) => {
  const colorModeColor = useColorModeValue('complete.500', 'complete.100');

  return (
    <Flex flexDir={'column'} gap={1} {...rest}>
      {!!label && <Text variant={'body-sm-muted'}>{label}</Text>}
      <HStack>
        {!!icon && <Icon as={icon} color={colorModeColor} />}
        <Text variant={'body-sm'}>
          {!value || value === '' ? fallback : value}
        </Text>
      </HStack>
    </Flex>
  );
};
