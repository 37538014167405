import { z } from 'zod';

// Currency
export const Currency = z.enum(['USD']);
export type Currency = z.infer<typeof Currency>;

// Monetary Value
export const MonetaryValue = z.object({
  amount: z.coerce.number().nonnegative(),
  currency: Currency,
});
export type MonetaryValue = z.infer<typeof MonetaryValue>;

// Dollar Amount
export const DollarAmount = MonetaryValue.merge(
  z.object({ currency: Currency.default('USD') }),
);
export type DollarAmount = z.infer<typeof DollarAmount>;

// Optional Dollar Amount
export const OptionalDollarAmount = z.object({
  amount: z.number().min(0).nullable().optional(),
  currency: Currency.default('USD'),
});
export type OptionalDollarAmount = z.infer<typeof OptionalDollarAmount>;

// Quantity Response
export const QuantityResponse = z.object({
  quantity: z.number().min(0),
  unitCost: DollarAmount,
});
export type QuantityResponse = z.infer<typeof QuantityResponse>;
