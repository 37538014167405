import {
  ModalBodyProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ThemingProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import { CancelConfirmButtonProps } from '../Buttons';

export type ModalConfig = {
  disclosure?: {
    onOpen?: () => void;
    onClose?: () => void;
    isOpen: boolean;
  };
  open?: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element;
  size?: ThemingProps<'Modal'>['size'];
  contentStyle?: React.CSSProperties;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  footerProps?: ModalFooterProps;
  headerProps?: ModalHeaderProps;
  footer?: string | JSX.Element | null;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnOverlayClick?: boolean;
  showOverlay?: boolean;
  closeOnEsc?: boolean;
  primaryActionButton?: CancelConfirmButtonProps['primaryActionButton'];
  secondaryActionButton?: CancelConfirmButtonProps['secondaryActionButton'];
  closeButton?: CancelConfirmButtonProps['cancelButton'];
};

export type ModalModel = {
  isOpen: boolean;
  title: string | JSX.Element;
  content?: string | JSX.Element;
  events: {
    open: () => void;
    close: () => void;
  };
  size?: ThemingProps<'Modal'>['size'];
  contentStyle?: React.CSSProperties;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  footerProps?: ModalFooterProps;
  headerProps?: ModalHeaderProps;
  buttonSize?: ThemingProps<'Button'>['size'];
  footer?: string | JSX.Element | null;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnOverlayClick?: boolean;
  showOverlay?: boolean;
  closeOnEsc?: boolean;
  primaryActionButton?: CancelConfirmButtonProps['primaryActionButton'];
  handlePrimaryAction?: () => void;
  secondaryActionButton?: CancelConfirmButtonProps['secondaryActionButton'];
  handleSecondaryAction?: () => void;
  closeButton?: CancelConfirmButtonProps['cancelButton'];
};

export const useModal = ({
  content,
  open = false,
  title,
  size,
  contentStyle,
  bodyProps,
  contentProps,
  footerProps,
  headerProps,
  footer,
  disclosure,
  closeOnOverlayClick = true,
  showOverlay = true,
  closeOnEsc = true,
  primaryActionButton,
  secondaryActionButton,
  closeButton,
}: ModalConfig): ModalModel => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClose = () => {
    setIsOpen(false);
    closeButton?.onClick?.();
    // onClose?.();
  };

  const handlePrimaryAction = () => {
    setIsOpen(false);
    primaryActionButton?.onClick?.();
    // onClose?.();
  };

  const handleSecondaryAction = () => {
    setIsOpen(false);
    secondaryActionButton?.onClick();
    // onClose?.();
  };

  return {
    isOpen,
    title,
    content,
    events: {
      open: () => {
        setIsOpen(true);
        disclosure?.onOpen?.();
      },
      close: handleClose,
    },
    size,
    contentStyle,
    bodyProps,
    contentProps,
    footerProps,
    headerProps,
    footer,
    closeOnOverlayClick,
    showOverlay,
    closeOnEsc,
    primaryActionButton,
    handlePrimaryAction,
    secondaryActionButton,
    handleSecondaryAction,
    closeButton,
  };
};
