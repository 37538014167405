import { z } from 'zod';
import { CSISectionId, CsiCodeId, TradeId } from './ids';
import { SelectValue } from './options';
import { InfiniteQuerySelect } from './queries';

// CSI Sections
// TODO: Revisit fix for estimation not loading pre demo
export const CsiCode = z.object({
  id: CsiCodeId,
  division: z.string().optional(),
  code: z.string(),
  description: z.string(),
  cleanCode: z.string().optional(),
  orderableCode: z.string().optional(),
});
export type CsiCode = z.infer<typeof CsiCode>;

// CSI Code Input
export const CsiCodeInput = CsiCode.extend({
  id: CsiCodeId.optional(),
});
export type CsiCodeInput = z.infer<typeof CsiCodeInput>;

// CSI Section Options
export const CSISectionOption = SelectValue.merge(
  z.object({
    value: CSISectionId,
  }),
);
export type CSISectionOption = z.infer<typeof CSISectionOption>;

// CSI Section Options
export const CSISectionInput = CsiCode;
export type CSISectionInput = z.infer<typeof CSISectionInput>;

// CSI Code Query
export const CsiCodeQuery = z.object({
  search: z.string().optional(),
  selectedValue: CsiCodeId.optional(),
});
export type CsiCodeQuery = z.infer<typeof CsiCodeQuery>;

// Create CSI Section Input
export const CreateCSISectionInput = CsiCode.omit({
  id: true,
});
export type CreateCSISectionInput = z.infer<typeof CreateCSISectionInput>;

// CSI Code Select Query
export const CsiCodeSelectQuery = InfiniteQuerySelect.extend({
  cursor: CsiCodeId.nullish(),
  query: CsiCodeQuery,
});
export type CsiCodeSelectQuery = z.infer<typeof CsiCodeSelectQuery>;

// TODO: Rethink Trades trades and remove it possible
export const Trade = z.object({
  id: TradeId,
  name: z.string().min(1),
  code: z.string().nullish(),
  description: z.string().nullish(),
});
export type Trade = z.infer<typeof Trade>;
