import { z } from 'zod';
import {
  BidFormResponseTypes,
  InternalRepresentation,
} from './bid-forms-common';
import { DollarAmount, QuantityResponse } from './currency';
import { IDNumber, ProjectId } from './ids';
import { TextMax255, TextMax512, WholeNumber } from './validators';

// Question Response Types
export const QuestionResponseTypes = z.enum([
  'Monetary',
  'Quantity',
  'Yes/No',
  'Percentage',
  'Text',
]);
export type QuestionResponseTypes = z.infer<typeof QuestionResponseTypes>;

// Bid Form Template Question
export const BidFormTemplateQuestion = z.object({
  id: IDNumber.optional(),
  projectId: ProjectId.optional().nullish(),
  orderId: WholeNumber,
  question: TextMax512,
  responseType: QuestionResponseTypes,
  required: z.boolean(),
  uuid: z.string(),
  baseAnswer: BidFormResponseTypes.optional(),
  totalCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  estimateLineItemId: z.string().optional().nullish(),
  internalRepresentation: InternalRepresentation.optional().nullish(),
  unpublishedChanges: z.boolean().default(false).optional(),
});
export type BidFormTemplateQuestion = z.infer<typeof BidFormTemplateQuestion>;

// Bid form template section
export const BidFormTemplateSection = z.object({
  id: IDNumber.optional(),
  projectId: ProjectId.optional().nullish(),
  title: TextMax255,
  orderId: WholeNumber,
  questions: z.array(BidFormTemplateQuestion),
  totalCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  maxRows: WholeNumber.optional(),
});
export type BidFormTemplateSection = z.infer<typeof BidFormTemplateSection>;

// Bid form template
export const BidFormTemplate = z.object({
  id: IDNumber,
  version: WholeNumber,
  formTemplateId: IDNumber,
  name: TextMax255,
  updatedAt: z.date(),
  sections: z.array(BidFormTemplateSection),
  totalCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  awardedCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  projectId: ProjectId.optional(),
  unpublishedChanges: z.boolean().default(false),
  isProjectForm: z.boolean().optional().default(false),
});
export type BidFormTemplate = z.infer<typeof BidFormTemplate>;

// Bid form draft
export const BidFormDraft = z.object({
  id: IDNumber,
  version: WholeNumber,
  formTemplateId: IDNumber,
  name: TextMax255,
  updatedAt: z.date(),
  sections: z.array(BidFormTemplateSection),
  totalCost: DollarAmount.default({ amount: 0, currency: 'USD' }).optional(),
  projectId: ProjectId,
  unpublishedChanges: z.boolean().default(false),
});
export type BidFormDraft = z.infer<typeof BidFormDraft>;

// Bid Form Monetary Question
export const BidFormMonetaryQuestion = BidFormTemplateQuestion.extend({
  baseAnswer: DollarAmount,
  responseType: z.enum([QuestionResponseTypes.enum.Monetary]),
});
export type BidFormMonetaryQuestion = z.infer<typeof BidFormMonetaryQuestion>;

// Bid Form Quantity Question
export const BidFormQuantityQuestion = BidFormTemplateQuestion.extend({
  baseAnswer: QuantityResponse,
  responseType: z.enum([QuestionResponseTypes.enum.Quantity]),
});
export type BidFormQuantityQuestion = z.infer<typeof BidFormQuantityQuestion>;

// Bid Form Text Question
export const BidFormTextQuestion = BidFormTemplateQuestion.extend({
  baseAnswer: z.string(),
  responseType: z.enum([QuestionResponseTypes.enum.Text]),
});
export type BidFormTextQuestion = z.infer<typeof BidFormTextQuestion>;

// Bid Form Boolean Question
export const BidFormBooleanQuestion = BidFormTemplateQuestion.extend({
  baseAnswer: z.boolean(),
  responseType: z.enum([QuestionResponseTypes.enum['Yes/No']]),
});
export type BidFormBooleanQuestion = z.infer<typeof BidFormBooleanQuestion>;

// Bid Form Percentage Question
export const BidFormPercentageQuestion = BidFormTemplateQuestion.extend({
  baseAnswer: z.string(),
  responseType: z.enum([QuestionResponseTypes.enum.Percentage]),
});
export type BidFormPercentageQuestion = z.infer<
  typeof BidFormPercentageQuestion
>;
