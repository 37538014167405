import { TextureType } from '@tb/gc-prisma';
import { z } from 'zod';
import { TextMax255 } from './validators';

// Texture Options
export const TextureOptions = z.object({
  label: TextMax255,
  value: z.nativeEnum(TextureType),
});
export type TextureOptions = z.infer<typeof TextureOptions>;

// Calculated Fields
export const CalculatedFields = z.record(
  z.enum(['area', 'length', 'volume', 'segmentCount']),
  z.number(),
);
export type CalculatedFields = z.infer<typeof CalculatedFields>;

// Dimension
export const Dimension = z.object({
  feet: z.number().nonnegative().default(0),
  inches: z.number().nonnegative().default(0),
});
export type Dimension = z.infer<typeof Dimension>;

// Dimensions
export const Dimensions = z.object({
  // TODO: Replace this at some point with thicknezz
  thickness: z.string().optional(),
  rise: z.number().optional(),
  run: z.number().optional(),
  thicknezz: z.number().optional(),
  height: z.number().optional(),
  depth: z.number().optional(),
  riseRun: z
    .object({
      rise: Dimension,
      run: Dimension,
    })
    .optional(),
  extraDim: Dimension.optional(),
});
export type Dimensions = z.infer<typeof Dimensions>;

// Secondary Measurements
export const SecondaryMeasurements = z.object({
  area: z.number().default(0),
  // TODO: rename this property to length
  perimeter: z.number().default(0),
  count: z.number().default(0),
  volume: z.number().default(0),
  segmentCount: z.number().default(0),
});
export type SecondaryMeasurements = z.infer<typeof SecondaryMeasurements>;

// Vertex
export const Vertex = z.array(z.number().min(2).max(2));
export type Vertex = [number, number];

// Vertex Type
export const VertexType = z.enum([
  'regular',
  'arc_start',
  'arc_anchor',
  'arc_end',
]);
export type VertexType = z.infer<typeof VertexType>;

// Point
export const Point = z.object({
  x: z.number(),
  y: z.number(),
});
export type Point = z.infer<typeof Point>;
