import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface ControlledMonetaryInputProps<
  FormValues extends FieldValues = FieldValues,
> extends UseControllerProps<FormValues> {
  label?: string;
  currency?: 'USD';
  isRequired?: boolean;
}

export const ControlledMonetaryInput = <
  FormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  shouldUnregister,
  currency = 'USD',
  isRequired,
}: ControlledMonetaryInputProps<FormValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const handleChange: CurrencyInputProps['onValueChange'] = (
    _valueAsString,
    _name,
    values,
  ) => {
    field.onChange(values?.float);
  };

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Input
        textAlign={'right'}
        placeholder={'Amount'}
        as={CurrencyInput}
        decimalsLimit={2}
        id={name}
        intlConfig={{ locale: window.navigator.language, currency }}
        defaultValue={field.value ? field.value : 0}
        onValueChange={handleChange}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};
