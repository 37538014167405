import { ThemeConfig, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { components } from './components';
import { foundations } from './foundations';

const darkModeConfig: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const themeV2 = extendTheme({
  components,
  zIndices: {
    modal: 1050,
  },
  ...foundations,
  ...darkModeConfig,

  styles: {
    global: (props: any) => {
      // console.log(props);
      return {
        ':root': {
          '--datepicker-bg': mode(
            'var(--chakra-colors-white)',
            'var(--chakra-colors-gray-700)',
          )(props),
          '--datepicker-date-bg-selected': mode(
            'var(--chakra-colors-complete-500)',
            'var(--chakra-colors-complete-100)',
          )(props),
          '--datepicker-time-bg-selected': mode(
            'var(--chakra-colors-complete-500)',
            'var(--chakra-colors-complete-100)',
          )(props),
          '--datepicker-close-icon-color': mode(
            'var(--chakra-colors-complete-500)',
            'var(--chakra-colors-complete-100)',
          )(props),
        },
      };
    },
  },
});

type ThemeV2 = typeof themeV2;

export { themeV2 };
export type { ThemeV2 };
