import {
  Currency,
  DollarAmount,
  MembershipWithUser,
  OptionalDollarAmount,
} from '../types';

export function difference(
  amount1: DollarAmount,
  amount2: DollarAmount,
): DollarAmount {
  const newAmount = Math.abs(amount1.amount - amount2.amount);

  return { currency: 'USD', amount: newAmount };
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

const randomColors = [
  { r: 3, g: 13, b: 186 },
  { r: 20, g: 192, b: 48 },
  { r: 28, g: 78, b: 121 },
  { r: 129, g: 205, b: 188 },
  { r: 43, g: 230, b: 26 },
  { r: 253, g: 170, b: 30 },
  { r: 92, g: 81, b: 51 },
  { r: 45, g: 101, b: 224 },
  { r: 61, g: 166, b: 142 },
  { r: 57, g: 177, b: 99 },
  { r: 69, g: 37, b: 233 },
  { r: 140, g: 174, b: 189 },
  { r: 77, g: 102, b: 120 },
  { r: 18, g: 150, b: 6 },
  { r: 197, g: 213, b: 34 },
  { r: 163, g: 173, b: 70 },
  { r: 166, g: 152, b: 41 },
  { r: 146, g: 7, b: 32 },
  { r: 80, g: 53, b: 197 },
  { r: 204, g: 251, b: 231 },
  { r: 53, g: 235, b: 115 },
  { r: 131, g: 159, b: 151 },
  { r: 107, g: 166, b: 164 },
  { r: 18, g: 188, b: 188 },
  { r: 131, g: 102, b: 119 },
  { r: 174, g: 161, b: 141 },
  { r: 144, g: 72, b: 68 },
  { r: 146, g: 239, b: 108 },
  { r: 134, g: 127, b: 237 },
  { r: 2, g: 42, b: 79 },
  { r: 242, g: 27, b: 213 },
  { r: 61, g: 104, b: 175 },
  { r: 245, g: 56, b: 207 },
  { r: 138, g: 89, b: 166 },
  { r: 100, g: 132, b: 209 },
  { r: 81, g: 59, b: 152 },
  { r: 90, g: 66, b: 155 },
  { r: 193, g: 190, b: 77 },
  { r: 183, g: 235, b: 236 },
  { r: 187, g: 159, b: 250 },
  { r: 235, g: 56, b: 107 },
  { r: 102, g: 82, b: 215 },
  { r: 230, g: 96, b: 252 },
  { r: 145, g: 222, b: 241 },
  { r: 132, g: 210, b: 168 },
  { r: 250, g: 130, b: 159 },
  { r: 44, g: 97, b: 200 },
  { r: 77, g: 243, b: 64 },
  { r: 116, g: 60, b: 49 },
  { r: 86, g: 76, b: 82 },
  { r: 248, g: 187, b: 14 },
  { r: 43, g: 191, b: 20 },
  { r: 235, g: 49, b: 74 },
  { r: 163, g: 73, b: 80 },
  { r: 53, g: 79, b: 59 },
  { r: 43, g: 80, b: 83 },
  { r: 159, g: 217, b: 203 },
  { r: 215, g: 149, b: 212 },
  { r: 45, g: 137, b: 57 },
  { r: 249, g: 83, b: 167 },
  { r: 247, g: 172, b: 35 },
  { r: 204, g: 234, b: 14 },
  { r: 168, g: 141, b: 239 },
  { r: 14, g: 201, b: 165 },
  { r: 29, g: 213, b: 68 },
  { r: 191, g: 249, b: 92 },
  { r: 120, g: 121, b: 2 },
  { r: 227, g: 2, b: 233 },
  { r: 57, g: 10, b: 51 },
  { r: 162, g: 68, b: 224 },
  { r: 34, g: 121, b: 244 },
  { r: 19, g: 118, b: 127 },
  { r: 237, g: 43, b: 39 },
  { r: 146, g: 72, b: 33 },
  { r: 122, g: 127, b: 142 },
  { r: 163, g: 173, b: 110 },
  { r: 123, g: 117, b: 94 },
  { r: 131, g: 225, b: 15 },
  { r: 125, g: 131, b: 238 },
  { r: 26, g: 146, b: 177 },
  { r: 200, g: 23, b: 21 },
  { r: 34, g: 253, b: 91 },
  { r: 189, g: 248, b: 12 },
  { r: 155, g: 217, b: 153 },
  { r: 58, g: 98, b: 123 },
  { r: 120, g: 170, b: 21 },
  { r: 114, g: 74, b: 175 },
  { r: 211, g: 209, b: 93 },
  { r: 91, g: 124, b: 192 },
  { r: 144, g: 29, b: 142 },
  { r: 221, g: 252, b: 25 },
  { r: 142, g: 100, b: 102 },
  { r: 18, g: 238, b: 120 },
  { r: 42, g: 211, b: 255 },
  { r: 135, g: 197, b: 220 },
  { r: 48, g: 96, b: 224 },
  { r: 144, g: 146, b: 163 },
  { r: 54, g: 27, b: 133 },
  { r: 46, g: 198, b: 201 },
  { r: 152, g: 55, b: 126 },
];

export const getRandomColor = () => {
  const color = randomColors[
    Math.floor(Math.random() * randomColors.length)
  ] || {
    r: 0,
    g: 0,
    b: 0,
    a: 0.8,
  };
  return { r: color.r || 0, g: color.g || 0, b: color.b || 0 };
};

/**
 * Gets the difference between two dates in days. Uses UTC for highest accuracy, but
 * it has some tradeoffs.
 *
 * See: https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
 *
 */
export function dateDiffInDays(a: Date, b: Date) {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const getCurrencyFormatFromContext = (
  value: OptionalDollarAmount['amount'],
  currency: Currency,
) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  }).format(Number((value || 0).toFixed(2)));
};
export const getDateFormat = (d: Date) => {
  return d.toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const capitalize = (string: string): string => {
  return string[0]?.toUpperCase() + string.slice(1).toLowerCase();
};

export const getCurrencyFormat = (
  value: number,
  currency: Currency,
  language: string,
) => {
  if (typeof value !== 'number') {
    value = Number(value);
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  }).format(Number(value.toFixed(2)));
};

export function formatEmail(email: string) {
  // return email.toLowerCase().replaceAll(/\s+/g, '').trim();
  return email.toLowerCase().replace(/\s+/g, '').trim();
}

export const getDaysSinceCreated = (createdAt: string) => {
  const now = Date.now();
  const created = new Date(createdAt).getTime();
  const hours = Math.floor((now - created) / (1000 * 60 * 60));
  const minutes = Math.floor((now - created) / (1000 * 60));

  if (hours < 1) {
    return minutes <= 1 ? '1 min ago' : `${minutes} mins ago`;
  } else if (hours === 1) {
    return `${hours} hr ago`;
  } else if (hours < 24) {
    return `${hours} hrs ago`;
  } else if (hours < 48) {
    return `${Math.floor(hours / 24)} day ago`;
  } else {
    return `${Math.floor(hours / 24)} days ago`;
  }
};

export function getAssigneeName(
  membership?: MembershipWithUser,
  fallback = 'Unassigned',
) {
  let name = fallback;
  if (!membership) {
    return name;
  }

  const user = membership.user;

  if (user) {
    name = user.name || name;
  } else {
    name = membership.invitedName || membership.invitedEmail || name;
  }

  return name;
}

export const getMonthDayTime = (date?: Date) => {
  const options: object = {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const d = date || new Date();
  return d.toLocaleDateString('en-US', options);
};

export const getMonthDayYear = (inputDate: Date) => {
  const options: object = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return inputDate.toLocaleDateString('en-US', options);
};
export const getMonthDay = (inputDate: Date) => {
  const options: object = {
    month: 'numeric',
    day: 'numeric',
  };
  return inputDate.toLocaleDateString('en-US', options);
};

export const capitalizeWords = (string: string): string => {
  return string
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ');
};

/**
 * Thanks [Stack Overflow](https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript)
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
