import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentStyleConfig = {
  variants: {
    folder: (props) => {
      return {
        tab: {
          border: 'none',
          fontSize: 'sm',
          boxSizing: 'border-box',
          mr: 1,
          color: props.colorMode === 'light' ? '#000' : '#fff',
          _hover: {
            bg: props.colorMode === 'light' ? '#fff' : 'gray.700',
            textDecoration: 'none',
            borderRadius: '8px 8px 0 0',
            border: 'none',
          },
          _selected: {
            bg: props.colorMode === 'light' ? '#fff' : 'gray.700',
            textDecoration: 'none',
            borderRadius: '8px 8px 0 0',
            border: 'none',
            _hover: {
              height: '100%',
              my: '0',
            },
          },
        },
      };
    },
    underline: (props) => {
      return {
        tablist: { borderBottomWidth: '1px' },
        tab: {
          fontSize: 'sm',
          fontWeight: '300',
          mr: 2,
          _selected: {
            borderBottom: '2px solid',
            borderColor:
              props.colorMode === 'light' ? 'complete.500' : 'complete.100',
          },
          _hover: {
            fontWeight: 'bold',
            backgroundColor:
              props.colorMode === 'light' ? '#efefef' : 'gray.800',
            borderBottom: `2px solid`,
            borderColor:
              props.colorMode === 'light' ? 'complete.500' : 'complete.100',
          },
        },
      };
    },
  },
};

//old version of folder tabs. Keep for reference
// folder: (props) => {
//       return {
// tab: {
//   padding: '6px 12px', // adjust padding to your preference
//   margin: 0,
//   marginRight: '2px', // small gap between tabs
//   borderRadius: '8px 8px 0 0', // rounded corners on top
//   background:
//     props.colorMode === 'dark' ? '#5F6368' : 'brand.softLight',
//   border: '1px solid #E0E0E0',
//   borderTop: '1px solid #E0E0E0',
//   borderRight: '1px solid #E0E0E0',
//   borderBottom: 'none',
//   position: 'relative', // needed for stacking and pseudo-elements
//   zIndex: 1, // default stack level
//   _selected: {
//     background: props.colorMode === 'dark' ? '#5F6368' : '#FFFFFF',
//     borderRadius: '8px 8px -25px -25px',
//     fontWeight: 'bold',
//     zIndex: 2,
//   },
//   _hover: {
//     background: 'brand.superLight',
//     borderColor: '#E0E0E0',
//     borderBottomColor: 'transparent',
//     fontWeight: 'bold',
//     zIndex: 2, // bring the hovered tab to the front
//   },
// },
//   };
// },
