import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';

type Orientation = 'vertical' | 'horizontal';

interface SplitPanelProps {
  children: React.ReactNode[];
  orientation?: Orientation;
  initialSize?: number;
  minSize?: number;
  maxSize?: number;
  resizeable?: boolean;
}

export const SplitPanel: React.FC<SplitPanelProps> = ({
  children,
  orientation = 'vertical',
  initialSize = 50,
  minSize = 10,
  maxSize = 90,
  resizeable = true,
}) => {
  const [size, setSize] = useState(initialSize);
  const [resizing, setResizing] = useState(false);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      let newSize: number;
      if (orientation === 'vertical') {
        newSize = (e.clientX / window.innerWidth) * 100;
      } else {
        newSize = (e.clientY / window.innerHeight) * 100;
      }

      newSize = Math.min(Math.max(newSize, minSize), maxSize);
      setSize(newSize);
    },
    [orientation, minSize, maxSize],
  );
  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    setResizing(false);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    setResizing(true);
  }, [handleMouseMove, handleMouseUp]);

  const dividerOrientation =
    orientation === 'vertical' ? 'vertical' : 'horizontal';

  const firstChildStyle = () => {
    if (orientation === 'vertical' && resizeable) {
      return { width: `${size}%` };
    } else if (orientation === 'horizontal' && resizeable) {
      return { height: `${size}%` };
    }
  };

  const secondChildStyle = () => {
    if (orientation === 'vertical' && resizeable) {
      return { width: `${100 - size}%` };
    } else if (orientation === 'horizontal' && resizeable) {
      return { height: `${100 - size}%` };
    } else {
      return { maxHeight: '50%' };
    }
  };

  return (
    <Box h={'100%'}>
      {orientation === 'vertical' ? (
        <HStack spacing="0" alignItems="stretch" h={'100%'} w={'100%'}>
          <Box style={firstChildStyle()} bg={'brand.white'} overflowX={'auto'}>
            {children[0]}
          </Box>
          {!!resizeable && (
            <Divider
              orientation={dividerOrientation}
              onMouseDown={handleMouseDown}
              opacity={1}
              w={2}
              bg={resizing ? 'blue.500' : 'gray.300'}
              zIndex={2}
              my={4}
            />
          )}
          <Box style={secondChildStyle()} bg={'brand.white'} overflowX={'auto'}>
            {children[1]}
          </Box>
        </HStack>
      ) : (
        <VStack
          spacing="0"
          alignItems="stretch"
          h={'100%'}
          w={'100%'}
          justifyContent={'space-between'}
        >
          <Box bg={'brand.white'} overflowY={'auto'} style={firstChildStyle()}>
            {children[0]}
          </Box>
          {!!resizeable && (
            <Divider
              orientation={dividerOrientation}
              opacity={1}
              onMouseDown={handleMouseDown}
              h={2}
              bg={resizing ? 'blue.500' : 'transparent'}
              _hover={{
                background: resizing ? 'blue.500' : 'gray.300',
              }}
              cursor={'row-resize'}
              zIndex={2}
              my={4}
            />
          )}
          <Box
            style={secondChildStyle()}
            bg={'brand.white'}
            zIndex={1}
            overflowY={'auto'}
          >
            {children[1]}
          </Box>
        </VStack>
      )}
    </Box>
  );
};
