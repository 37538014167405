import { z } from 'zod';
import { EnterpriseTypeId } from './ids';
import { EnterpriseTypeName } from './validators';
import { SelectValue } from './options';

export const EnterpriseType = z.object({
  id: EnterpriseTypeId,
  name: EnterpriseTypeName,
});
export type EnterpriseType = z.infer<typeof EnterpriseType>;

export const EnterpriseTypeSelect = SelectValue;
export type EnterpriseTypeSelect = z.infer<typeof EnterpriseTypeSelect>;
