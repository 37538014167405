import { z } from 'zod';
import { IDNumber, ProjectId } from './ids';

// Calendar events
export const CalendarEvent = z.object({
  id: z.union([IDNumber, ProjectId]),
  title: z.string(),
  start: z.date().optional(),
  end: z.date().optional(),
  allDay: z.boolean(),
  type: z.enum(['rfi', 'project', 'expected', 'dueDate']),
});
export type CalendarEvent = z.infer<typeof CalendarEvent>;
