import {
  TabProps as ChakraTabProps,
  Flex,
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  Tabs,
  TabsProps,
} from '@chakra-ui/react';
import Link from 'next/link';
import { Fragment, ReactNode, useMemo } from 'react';

export type TabProps = {
  /**
   * Required. The label of the tab. Also used as the title of the tab panel.
   */
  label: string;
  /**
   * Optional. Allows icons to be used in place of the label.
   */
  icon?: ReactNode;
  /**
   * Optional. The content of the tab panel to be rendered.
   */
  panel?: ReactNode;
  /**
   * Optional. Set to true to disable the tab.
   */
  isDisabled?: boolean;
  /**
   * Optional. Set an automation id for the tab for testing purposes.
   */
  testId?: string;
  /**
   * Optional. Set a link for the tab.
   */
  href?: string;

  /**
   * Optional. Set props to the Tab component from Chakra UI
   */
  tabProps?: Omit<ChakraTabProps, 'children'>;
};

type Props = {
  /**
   * Optional. Allows visible tab panel to be controlled externally
   */
  index?: number;
  /**
   * Optional. Allows hiding of tab list.
   */
  showTabList?: boolean;
  /**
   * Optional. Set the initially selected tab. Defaults to the first tab.
   */
  initialTab?: string;
  /**
   * Required. Provide an array of tabs to render.
   */
  tabs: TabProps[];
  /**
   * Optional. Provide styles override to the tabs list container
   */
  tabsListStyle?: React.CSSProperties;
  /**
   * @default undefined
   * @deprecated
   * Optional. Provide styles override to the tabs panel container
   */
  tabsPanelStyle?: React.CSSProperties;

  /**
   * @default undefined
   * Optional. Provide the props to the tab panels container from Chakra UI
   */
  tabPanelsProps?: Omit<TabPanelsProps, 'children'>;

  /**
   * @default undefined
   * @deprecated
   * Optional. Provide styles override to the tabs panel container
   */
  tabPanelStyle?: React.CSSProperties;

  /**
   * @default undefined
   * Optional. Provide the props to the tab panel container from Chakra UI
   */
  tabPanelProps?: Omit<TabPanelProps, 'children'>;

  /**
   * @default false
   * Optional. If true, tab panels will be rendered in a scrollable container.
   */
  overflow?: boolean;
  /**
   * Optional. Set the render behavior of the tab panels. Defaults to 'false' which renders the content within all tab panels up front.
   * If set to 'true', the content of the tab panels will be rendered only when the tab is selected.
   */
  isLazy?: boolean;

  /**
   * Optional. Pass props to the Tabs component from Chakra UI
   */
  tabsProps?: Omit<TabsProps, 'children'>;

  /**
   * Optional. Pass props to the TabList component from Chakra UI
   */
  tabListProps?: Omit<TabListProps, 'children'>;
};

export const TabsView = ({
  initialTab,
  tabs,
  tabsListStyle,
  tabsPanelStyle,
  tabPanelStyle = { overflowX: 'auto', whiteSpace: 'nowrap' },
  overflow,
  index,
  showTabList = true,
  isLazy = false,
  tabsProps,
  tabListProps,
  tabPanelProps,
  tabPanelsProps,
}: Props) => {
  const initialTabIndex = useMemo(() => {
    if (initialTab) {
      const index = tabs.findIndex((tab) => {
        // Default impl
        if (tab.label === initialTab) {
          return true;
        }

        // Expanded impl for url based default tabs
        if (tab.label.toLocaleLowerCase().replaceAll(' ', '') == initialTab) {
          return true;
        }

        return false;
      });
      return index === -1 ? 0 : index;
    }
    return 0;
  }, []);

  // Tab indicator does not show when overflow is set on TabList.
  // const getOverflowTabIndicator = () => {
  //   if (tabListProps) {
  //     return Object.keys(tabListProps).some((key) => key.includes('overflow'));
  //   }
  // };

  return (
    <Tabs
      defaultIndex={initialTabIndex}
      width="inherit"
      isLazy={isLazy}
      lazyBehavior="keepMounted"
      index={index}
      {...tabsProps}
      zIndex={0}
    >
      {!!showTabList && (
        <>
          <TabList pr={10} pl={4} style={tabsListStyle} {...tabListProps}>
            {tabs.map((tab) => (
              <Fragment key={tab.label}>
                {tab.href ? (
                  <Link href={tab.href} passHref>
                    <Tab
                      isDisabled={tab.isDisabled}
                      data-testid={tab.testId}
                      gap={1}
                      {...tab.tabProps}
                    >
                      <Flex gap={2} alignItems="center">
                        {tab.icon}
                        {tab.label}
                      </Flex>
                    </Tab>
                  </Link>
                ) : (
                  <Tab
                    isDisabled={tab.isDisabled}
                    data-testid={tab.testId}
                    gap={1}
                    {...tab.tabProps}
                  >
                    {tab.icon}
                    {tab.label}
                  </Tab>
                )}
              </Fragment>
            ))}
          </TabList>
          {/* {getOverflowTabIndicator() && (
            <TabIndicator mt="-2px" height="2px" bg="black" />
          )} */}
        </>
      )}
      <TabPanels
        overflow={overflow ? 'auto' : ''}
        style={tabsPanelStyle}
        {...tabPanelsProps}
      >
        {tabs.map((tab) => (
          <TabPanel
            key={tab.label}
            p={0}
            style={tabPanelStyle}
            {...tabPanelProps}
          >
            {tab.panel}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
