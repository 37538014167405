import { ComponentStyleConfig, defineStyleConfig } from '@chakra-ui/react';

export const Card: ComponentStyleConfig = defineStyleConfig({
  baseStyle: ({ colorMode }) => ({
    padding: '6',
    flexDir: 'column',
    display: 'flex',
    borderRadius: '8px',
    shadow: 'base',
    bg: colorMode === 'light' ? 'brand.white' : 'gray.700', // Adjust background color based on color mode
  }),
  variants: {
    solid: ({ colorMode }) => ({
      borderWidth: 0,
      borderColor:
        colorMode === 'light' ? 'brand.lightBorder' : 'brand.darkBorder', // Adjust border color for solid variant in dark mode
    }),
    outline: {
      borderWidth: '1px',
    },
  },
  defaultProps: {
    variant: 'outline',
  },
});
