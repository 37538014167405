import { defineStyleConfig, ComponentStyleConfig } from '@chakra-ui/react';

export const Card: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    padding: '24px',
    flexDir: 'column',
    display: 'flex',
    borderRadius: '8px',
    shadow: 'base',
    bg: 'brand.white',
  },
  variants: {
    solid: {
      borderWidth: 0,
    },
    outline: {
      borderWidth: '1px',
    },
  },
  defaultProps: {
    variant: 'outline',
  },
});
