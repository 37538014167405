import { z } from 'zod';
import { Bid, BidStatus } from './bid';
import { BidderId } from './ids';

// Bidder Status Bid
const BidderStatusBid = Bid.pick({ id: true, name: true, projectName: true });
export type BidderStatusBid = z.infer<typeof BidderStatusBid>;

// Bidder Status
export const BidderStatus = z.object({
  id: BidderId,
  status: BidStatus,
  bid: BidderStatusBid,
});
export type BidderStatus = z.infer<typeof BidderStatus>;
