import {
  Box,
  ChakraProps,
  ComponentWithAs,
  FlexProps,
  forwardRef,
  useStyleConfig,
} from '@chakra-ui/react';

export interface CardProps extends ChakraProps, FlexProps {
  variant?: 'solid' | 'outline';
}

export const Card: ComponentWithAs<'div', CardProps> = forwardRef<
  CardProps,
  'div'
>((props: CardProps, ref) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('Card', { variant });

  return <Box __css={styles} ref={ref} {...rest} />;
});
