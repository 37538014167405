import { Input, InputProps } from '@chakra-ui/react';
import { DollarAmount } from '@tb/common';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';

export const MonetaryInput = ({
  value,
  currency = 'USD',
  ...inputProps
}: {
  value: DollarAmount;
  currency?: string;
} & Omit<Omit<CurrencyInputProps, 'size'> & InputProps, 'value'>) => {
  const initialValue = value.amount ?? 0;

  return (
    <Input
      variant={'input-xs'}
      textAlign={'right'}
      placeholder={'Amount'}
      as={CurrencyInput}
      decimalsLimit={2}
      intlConfig={{ locale: window.navigator.language, currency }}
      defaultValue={initialValue}
      {...inputProps}
    />
  );
};
