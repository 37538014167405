import { z } from 'zod';
import { DollarAmount } from './currency';
import {
  ArchitectId,
  BidFormTemplateId,
  ClientId,
  OrderId,
  OwnerId,
  ProjectId,
  ProjectStageId,
  ProjectTypeId,
  TakeoffId,
} from './ids';
import { EstimatorName, ProjectName, TextMax255 } from './validators';

// Project Status
export const ProjectStatus = z.enum(['Active', 'Archived']);
export type ProjectStatus = z.infer<typeof ProjectStatus>;

// Project Stage
export const ProjectStage = z.object({
  id: ProjectStageId,
  orderId: OrderId,
  color: z.string(),
  name: TextMax255,
});
export type ProjectStage = z.infer<typeof ProjectStage>;

// Project
export const Project = z.object({
  id: ProjectId,
  name: ProjectName,
  client: z.string(),
  clientId: ClientId.optional(),
  address: z.string(),
  cityState: z.string().optional(),
  assignee: EstimatorName,
  owner: EstimatorName,
  ownerId: OwnerId,
  status: ProjectStatus,
  stage: ProjectStage.optional(),
  stages: z.array(ProjectStage),
  deadline: z.date().optional(),
  projectSize: z.number().optional(),
  start: z.date().optional(),
  end: z.date().optional(),
  code: z.string(),
  description: z.string().optional(),
  architect: z
    .object({
      id: ArchitectId,
      value: TextMax255,
    })
    .optional(),

  projectType: z
    .object({
      id: ProjectTypeId,
      value: TextMax255,
    })
    .optional(),
  projectValue: DollarAmount.default({ amount: 0, currency: 'USD' }),
  bidFormTemplateId: BidFormTemplateId.optional(),
  bidDueDate: z.date().optional().nullish(),
  rfiDueDate: z.date().optional().nullish(),
  takeoffId: TakeoffId.optional().nullish(),
});
export type Project = z.infer<typeof Project>;
