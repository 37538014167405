import * as borderRadius from './borderRadius';
import * as borders from './borders';
import * as breakpoints from './breakpoints';
import * as colors from './colors';
import * as shadows from './shadows';
import * as sizes from './sizes';
import * as spacing from './spacing';
import { typography } from './typography';

export const foundations = {
  ...borderRadius,
  ...breakpoints,
  ...colors,
  ...sizes,
  ...spacing,
  ...typography,
  ...shadows,
  ...borders,
};
