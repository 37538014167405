import { ComponentStyleConfig } from '@chakra-ui/react';

export const Checkbox: ComponentStyleConfig = {
  variants: {
    'styled-checkbox': ({ colorMode }) => ({
      control: {
        borderRadius: 2,
        border: '1px solid #D1D5DB',
        backgroundColor: '#F9FAFB',
        _checked: {
          background: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          borderColor: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          _hover: {
            background: colorMode === 'dark' ? 'complete.100' : 'complete.500',
            borderColor: colorMode === 'dark' ? 'complete.100' : 'complete.500',
          },
        },
      },
      label: {
        fontSize: 'sm',
        lineHeight: 'base',
        fontWeight: 'normal',
        color: 'black',
      },
    }),
    rounded: {
      control: {
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'gray.200',
        backgroundColor: 'gray.50',
      },
    },
  },
};
