import {
  FormControl,
  FormLabel,
  InputProps,
  Select,
  SelectProps,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FieldErrors,
  FieldValues,
  Path,
  UseFormRegisterReturn,
} from 'react-hook-form';

type Props<T extends FieldValues> = {
  label: string;
  name: Path<T>;
  testId?: string;
  isRequired?: boolean;
  placeholder?: string;
  errors: FieldErrors<T>;
  register: () => UseFormRegisterReturn;
  size?: InputProps['size'];
  isDisabled?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  autoFocus?: boolean;
  options: { label: string; value: string | number; disabled?: boolean }[];
  variant?: SelectProps['variant'];
  orientation?: 'vertical' | 'horizontal';
  flex?: number;
};

export const ControlledSimpleSelect = <T extends FieldValues>({
  label,
  name,
  testId,
  isRequired,
  errors,
  register,
  variant,
  isDisabled,
  size = 'md',
  textAlign,
  autoFocus,
  options,
  orientation = 'vertical',
  placeholder,
  flex,
}: Props<T>) => {
  const isInvalid = name in errors;
  const dataTestId = testId || `${name}-input`;
  const bgColor = useColorModeValue('white', 'gray.900');

  return (
    <FormControl
      isRequired={isRequired || false}
      flex={flex}
      {...(orientation === 'horizontal' && { display: 'flex' })}
      {...(orientation === 'horizontal' && { alignItems: 'center' })}
    >
      <FormLabel fontSize={size}>{label}</FormLabel>
      <Select
        placeholder={placeholder}
        size={size}
        isInvalid={isInvalid}
        data-testid={dataTestId}
        {...register()}
        variant={variant}
        disabled={isDisabled || false}
        bg={bgColor}
        textAlign={textAlign ?? 'left'}
        autoFocus={autoFocus}
      >
        {options.map(({ label, value, disabled }) => {
          return (
            <option value={value} key={value} disabled={disabled}>
              {label}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};
