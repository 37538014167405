import { ComponentStyleConfig } from '@chakra-ui/react';

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    textTransform: 'none',
  },
  variants: {
    orange: ({ colorMode }) => ({
      color: 'brand.orange',
      bg: 'brand.softOrange',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
    blue: ({ colorMode }) => ({
      color: 'brand.blue',
      bg: 'brand.softBlue',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
    green: ({ colorMode }) => ({
      color: 'brand.green',
      bg: 'brand.softGreen',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
    red: ({ colorMode }) => ({
      color: 'brand.unresolved',
      bg: 'brand.softRed',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
    purple: ({ colorMode }) => ({
      color: 'brand.purple',
      bg: 'brand.softPurple',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
    teal: ({ colorMode }) => ({
      color: 'brand.teal',
      bg: 'brand.softTeal',
      filter: colorMode === 'dark' ? 'saturate(2)' : 'none',
    }),
  },
};

// export const Badge: ComponentStyleConfig = {
//   baseStyle: {
//     textTransform: 'none',
//   },
//   variants: {
//     orange: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'brand.softOrange' : 'brand.softOrange',
//       color: colorMode === 'dark' ? 'brand.orange' : 'brand.orange',
//     }),
//     blue: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'brand.softBlue' : 'brand.softBlue',
//       color: colorMode === 'dark' ? 'brand.blue' : 'brand.blue',
//     }),
//     green: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'darkBrand.softGreen' : 'brand.softGreen',
//       color: colorMode === 'dark' ? 'darkBrand.green' : 'brand.green',
//     }),
//     red: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'darkBrand.softRed' : 'brand.softRed',
//       color: colorMode === 'dark' ? 'darkBrand.unresolved' : 'brand.unresolved',
//     }),
//     purple: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'darkBrand.softPurple' : 'brand.softPurple',
//       color: colorMode === 'dark' ? 'darkBrand.purple' : 'brand.purple',
//     }),
//     teal: ({ colorMode }) => ({
//       bg: colorMode === 'dark' ? 'darkBrand.softTeal' : 'brand.softTeal',
//       color: colorMode === 'dark' ? 'darkBrand.teal' : 'brand.teal',
//     }),
//   },
// };
