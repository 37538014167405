import { PageSpinner } from '../PageSpinner';
import { DefaultFallback, FallBackProps } from './DefaultFallback';

type Props<T> = {
  [Key in keyof T]: NonNullable<T[Key]>;
};

type MakeWrapperProps<T> = {
  isLoading?: boolean;
  fullHeight?: boolean;
  queries: {
    [Key in keyof T]: T[Key];
  };
  ignoreFallback?: boolean;
  fallback?: FallBackProps;
  children(props: Props<T>): JSX.Element;
};

const isEmptyArray = (data: any) => {
  if (Array.isArray(data) && data.length === 0) {
    return true;
  }
  return false;
};
export const DataWrapper = <T extends Record<string, unknown>>({
  isLoading,
  fullHeight,
  queries,
  fallback,
  children,
  ignoreFallback,
}: MakeWrapperProps<T>) => {
  if (isLoading) {
    return <PageSpinner fullHeight={fullHeight} />;
  }
  if (!queries && !ignoreFallback) {
    return <DefaultFallback {...fallback} />;
  }
  const ready = Object.entries(queries).map(([_key, data]) => {
    if (!data || isEmptyArray(data)) {
      // console.log(data);
      return false;
    }
    return true;
  });
  if (ready.some((v) => v === false) && !ignoreFallback) {
    return <DefaultFallback {...fallback} />;
  }
  const qs: {
    [Key in keyof T]: NonNullable<T[Key]>;
  } = queries as any;
  return children({
    ...qs,
  });
};
