import { Kbd, useColorModeValue } from '@chakra-ui/react';
import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar';
import React, { CSSProperties } from 'react';
import { isMacOs } from 'react-device-detect';
/**
 * Styles from [KBar Example App]{@link https://github.com/timc1/kbar/blob/main/example/src/App.tsx}
 */
export const CommandBar = () => {
  const colorModeColor = useColorModeValue('#000', '#fff');
  const colorModeBg = useColorModeValue('#fff', '#1A202C');

  const searchStyle: CSSProperties = {
    padding: '12px 16px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
    outline: 'none',
    border: 'none',
    background: colorModeBg,
    color: colorModeColor,
    zIndex: 1000,
  };

  const animatorStyle: CSSProperties = {
    maxWidth: '600px',
    width: '100%',
    background: colorModeBg,
    color: colorModeColor,
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '2px 4px 4px 1px rgba(0, 0, 0, 0.25)',
    zIndex: 1000,
  };

  return (
    <KBarPortal>
      <KBarPositioner style={{ zIndex: 1000 }}>
        <KBarAnimator style={animatorStyle}>
          <KBarSearch style={searchStyle} />
          <RenderResults />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};

function RenderResults() {
  const groupNameStyle: CSSProperties = {
    padding: '8px 16px',
    fontSize: '10px',
    textTransform: 'uppercase' as const,
    opacity: 0.5,
    zIndex: 1000,
  };
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId?: ActionId | null;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    const getOsSpecificModKey = (hotkey: string) => {
      let formattedHotkey = '';
      if (hotkey.includes('$mod')) {
        if (isMacOs) {
          formattedHotkey = hotkey.replace('$mod', 'Command');
        } else {
          formattedHotkey = hotkey.replace('$mod', 'Control');
        }
        return formattedHotkey;
      }
      return hotkey;
    };

    const colorModeColor = useColorModeValue(
      'var(--chakra-colors-brand-softGreen)',
      'var(--chakra-colors-complete-100)',
    );

    return (
      <div
        ref={ref}
        style={{
          padding: '12px 16px',
          background: active ? colorModeColor : 'transparent',
          borderLeft: `2px solid ${
            active ? 'var(--chakra-colors-complete-400)' : 'transparent'
          }`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
            fontSize: 14,
          }}
        >
          {!!action.icon && action.icon}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <span
                      style={{
                        opacity: 0.5,
                        marginRight: 8,
                      }}
                    >
                      {ancestor.name}
                    </span>
                    <span
                      style={{
                        marginRight: 8,
                      }}
                    >
                      &rsaquo;
                    </span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {!!action.subtitle && (
              <span style={{ fontSize: 12 }}>{action.subtitle}</span>
            )}
          </div>
        </div>
        {!!action.shortcut?.length && (
          <div
            aria-hidden
            style={{ display: 'grid', gridAutoFlow: 'column', gap: '4px' }}
          >
            {action.shortcut.map((sc) => {
              return <Kbd key={sc}>{getOsSpecificModKey(sc)}</Kbd>;
            })}
          </div>
        )}
      </div>
    );
  },
);

ResultItem.displayName = 'ResultItem';
