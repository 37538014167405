import { z } from 'zod';
import { IDNumber } from './ids';

// Infinite Query Input
export const InfiniteQueryInput = z.object({
  limit: z.number().nullish(),
  cursor: IDNumber.nullish(),
});
export type InfiniteQueryInput = z.infer<typeof InfiniteQueryInput>;

// Infinite Query Select
export const InfiniteQuerySelect = z.object({
  limit: z.number(),
  cursor: IDNumber.nullish(),
});
export type InfiniteQuerySelect = z.infer<typeof InfiniteQuerySelect>;
