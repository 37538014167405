import { z } from 'zod';
import { DollarAmount, QuantityResponse } from './currency';
import { TextMax512 } from './validators';

// Quantity Response Level Event Data
export const QuantityResponseLevelEventData = z.object({
  newValue: QuantityResponse,
  oldValue: QuantityResponse,
  notes: TextMax512.optional(),
});
export type QuantityResponseLevelEventData = z.infer<
  typeof QuantityResponseLevelEventData
>;

// Monetary Response Level Event Data
export const MonetaryResponseLevelEventData = z.object({
  newValue: DollarAmount,
  oldValue: DollarAmount,
  notes: TextMax512.optional(),
});
export type MonetaryResponseLevelEventData = z.infer<
  typeof MonetaryResponseLevelEventData
>;

// Boolean Response Level Event Data
export const BooleanResponseLevelEventData = z.object({
  newValue: z.boolean(),
  oldValue: z.boolean(),
  notes: TextMax512.optional(),
});
export type BooleanResponseLevelEventData = z.infer<
  typeof BooleanResponseLevelEventData
>;

// Percentage Response Level Event Data
export const PercentageResponseLevelEventData = z.object({
  newValue: z.string(),
  oldValue: z.string(),
  notes: TextMax512.optional(),
});

export type PercentageResponseLevelEventData = z.infer<
  typeof PercentageResponseLevelEventData
>;

// Text Response Level Event Data
export const TextResponseLevelEventData = z.object({
  newValue: TextMax512,
  oldValue: TextMax512,
  notes: TextMax512.optional(),
});
export type TextResponseLevelEventData = z.infer<
  typeof TextResponseLevelEventData
>;

// Bid Level Event Data
export const BidLevelEventData = z.union([
  QuantityResponseLevelEventData,
  MonetaryResponseLevelEventData,
  BooleanResponseLevelEventData,
  PercentageResponseLevelEventData,
  TextResponseLevelEventData,
]);
export type BidLevelEventData = z.infer<typeof BidLevelEventData>;

// "Changed" Event
export const ChangedEvent = z.object({
  createdAt: z.date(),
  createdBy: z.string().optional(),
});

export const BidLevelEvent = ChangedEvent.extend({
  data: BidLevelEventData,
});
