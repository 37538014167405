import { z } from 'zod';

// Number Ids
export const IDNumber = z.number().gt(0);
export const IDNumbers = z.array(IDNumber);
export type IDNumber = z.infer<typeof IDNumber>;

// String Ids
export const IDString = z.string().cuid().or(z.string());
export type IDString = z.infer<typeof IDString>;

// Organization Id
export const OrganizationId = IDNumber;
export type OrganizationId = z.infer<typeof OrganizationId>;

// CSI Code
export const CsiCodeId = IDString;
export type CsiCodeId = z.infer<typeof CsiCodeId>;

// Trade
export const TradeId = IDNumber;
export type TradeId = z.infer<typeof TradeId>;

// Bids
export const BidId = IDString;
export type BidId = z.infer<typeof BidId>;

// Biders
export const BidderId = IDNumber;
export type BidderId = z.infer<typeof BidderId>;

// Subcontractors
export const SubContractorId = IDString;
export type SubContractorId = z.infer<typeof SubContractorId>;

// Subcontractor Member
export const SubcontractorMemberId = IDNumber;
export type SubcontractorMemberId = z.infer<typeof SubcontractorMemberId>;

//  Subcontractor Organization
export const SubcontractorOrganizationId = IDString;
export type SubcontractorOrganizationId = z.infer<
  typeof SubcontractorOrganizationId
>;

// Enterprise
export const EnterpriseTypeId = IDNumber;
export type EnterpriseTypeId = z.infer<typeof EnterpriseTypeId>;

//DBE Certificates
export const DBECertificateId = IDNumber;
export type DBECertificateId = z.infer<typeof DBECertificateId>;

// CSI Code
export const CSISectionId = IDString;
export type CSISectionId = z.infer<typeof CSISectionId>;

// Project
export const ProjectId = IDString;
export type ProjectId = z.infer<typeof ProjectId>;

// Project Stage
export const ProjectStageId = IDNumber;
export type ProjectStageId = z.infer<typeof ProjectStageId>;

// Project Stages Configuration
export const ProjectStagesConfigurationId = IDNumber;
export type ProjectStagesConfigurationId = z.infer<
  typeof ProjectStagesConfigurationId
>;

// Order Id
export const OrderId = IDNumber;
export type OrderId = z.infer<typeof OrderId>;

// Client Id
export const ClientId = IDNumber;
export type ClientId = z.infer<typeof ClientId>;

// Owner Id
export const OwnerId = IDNumber;
export type OwnerId = z.infer<typeof OwnerId>;

// Architect Id
export const ArchitectId = IDNumber;
export type ArchitectId = z.infer<typeof ArchitectId>;

// Project Type Id
export const ProjectTypeId = IDNumber;
export type ProjectTypeId = z.infer<typeof ProjectTypeId>;

// Bid Form Template Id
export const WageTypeId = IDNumber;
export type WageTypeId = z.infer<typeof WageTypeId>;

// Bid form responses
export const BidFormResponseId = IDNumber;
export type BidFormResponseId = z.infer<typeof BidFormResponseId>;

// Bid form templates
export const BidFormTemplateId = IDNumber;
export type BidFormTemplateId = z.infer<typeof BidFormTemplateId>;

// Takeoff
export const TakeoffId = IDString;
export type TakeoffId = z.infer<typeof TakeoffId>;
