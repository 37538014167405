import { ComponentStyleConfig } from '@chakra-ui/react';
import { Button } from './button';

export const Link: ComponentStyleConfig = {
  variants: {
    'overview-link': ({ colorMode }) => ({
      fontSize: 'xs',
      color: colorMode === 'dark' ? 'complete.100' : 'complete.500',
      textDecoration: 'underline',
    }),
    nav: ({ colorMode }) => ({
      ...Button?.variants?.nav,
      margin: 0,
      height: 'inherit',
      _hover: {
        textDecoration: 'none',
      },
      color: colorMode === 'dark' ? 'darkComplete.400' : 'complete.500',
    }),
    nav_active: ({ colorMode }) => ({
      ...Button?.variants?.nav,
      margin: 0,
      height: 'inherit',
      _hover: {
        textDecoration: 'none',
      },
      color: colorMode === 'dark' ? 'darkComplete.400' : 'complete.500',
      borderRadius: 'lg',
      fontWeight: 'bold',
    }),
  },
};
