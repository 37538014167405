import { State } from '@tb/gc-prisma';
import { z } from 'zod';
import { Bid } from './bid';
import { BidderStatus } from './bid-commons';
import { CsiCode, Trade } from './csi';
import { DollarAmount } from './currency';
import { EnterpriseType } from './enterpriseType';
import {
  SubContractorId,
  SubcontractorMemberId,
  SubcontractorOrganizationId,
} from './ids';
import { Tag } from './tag';
import { TextMax255 } from './validators';

export const SubContractorQualificationStatusArray = [
  'PENDING',
  'QUALIFIED',
  'NOT_QUALIFIED',
];

export const SubContractorQualificationStatus = z.enum([
  'PENDING',
  'QUALIFIED',
  'NOT_QUALIFIED',
]);

export const PreferredStatusTypes = z.enum(['Preferred', 'Do not use']);
export const LaborTypes = z.enum(['Union', 'Non-Union', 'Prevailing Wages']);
export const LaborTypeSelect = z.object({
  value: z.string(),
  label: z.string(),
});

// export const Bid = z.object({
//   id: BidId,
//   name: TextMax255,
//   status: BidStatus,
// });

export const SubContractorMember = z.object({
  id: SubcontractorMemberId,
  subcontractorOrganizationId: SubcontractorOrganizationId.optional(),
  name: TextMax255,
  phone: TextMax255.optional().nullish(),
  cellPhone: TextMax255.optional().nullish(),
  officePhone: TextMax255.optional().nullish(),
  email: z.string().email().optional(),
  title: TextMax255.optional().nullish(),
  biddingFor: z.array(Bid).default([]),
  isOwner: z.boolean(),
});

export const QualificationStatus = z.enum([
  'Pending',
  'Not Qualified',
  'Qualified',
]);
export type QualificationStatus = z.infer<typeof QualificationStatus>;

export const SubContractor = z.object({
  id: SubContractorId.optional(),
  name: z.string().optional(),
  location: z.string().optional(),
  streetAddress: z.string().optional(),
  city: z.string().optional(),
  state: z.nativeEnum(State),
  zipCode: z.string().optional(),
  preferredStatus: PreferredStatusTypes.optional(),
  trades: z.array(Trade),
  csiCodes: z.array(CsiCode),
  contacts: z.array(SubContractorMember),
  qualificationStatus: QualificationStatus,
  qualificationDate: z.date().optional(),
  perProjectLimit: DollarAmount.optional(),
  annualProjectLimit: DollarAmount.optional(),
  laborTypes: z.array(LaborTypes),
  enterpriseTypes: z.array(EnterpriseType),
  tags: z.array(Tag),
  website: z.string().optional(),
  bidder: z.array(BidderStatus),
});

export const UpdatePreferredStatusInput = z.object({
  id: SubContractorId,
  status: PreferredStatusTypes,
});

const AddressInputs = z.object({
  streetAddress: z.string().optional(),
  city: z.string().optional(),
  state: z.nativeEnum(State),
  zipCode: z.string().optional(),
});

export const fullLocation = z
  .function()
  .args(AddressInputs)
  .returns(z.string())
  .implement((sub) => {
    const { streetAddress, city, state, zipCode } = sub;
    const delim = streetAddress || city ? ',' : '';
    return `${streetAddress || ''} ${city || ''}${delim} ${state} ${
      zipCode || ''
    }`
      .replaceAll(/\s+/g, ' ')
      .trim();
  });

export const cityState = z
  .function()
  .args(AddressInputs)
  .returns(z.string())
  .implement((sub) => {
    const { city, state } = sub;
    return `${city || ''}, ${state}`.replaceAll(/\s+/g, ' ').trim();
  });

export type SubContractor = z.infer<typeof SubContractor>;
export type PreferredStatusTypes = z.infer<typeof PreferredStatusTypes>;
export type LaborTypes = z.infer<typeof LaborTypes>;
export type LaborTypeSelect = z.infer<typeof LaborTypeSelect>;
export type SubContractorMember = z.infer<typeof SubContractorMember>;
export type UpdatePreferredStatusInput = z.infer<
  typeof UpdatePreferredStatusInput
>;
export type SubContractorQualificationStatus = z.infer<
  typeof SubContractorQualificationStatus
>;
