import {
  InputGroup,
  InputProps,
  InputRightElement,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { ReactNode, useRef, useState } from 'react';

export interface ConstructionInputProps extends InputProps {
  handleChange: (feet: number, inches: number) => void;
  label?: string;
  icon?: ReactNode;
  showLabel?: boolean;
  feet: number;
  inches: number;
}

export const ConstructionInput = (props: ConstructionInputProps) => {
  const { handleChange, feet, icon, inches } = props;
  const [isFocused, setIsFocused] = useState(false);
  const numberInputRef = useRef<HTMLInputElement>(null);

  const convertToFeetAndInches = (value: string) => {
    const numericValue =
      typeof value === 'string' ? parseInt(value, 10) : value;

    let feetValue = Math.floor(numericValue / 100);
    let inchesValue = numericValue % 100;

    // Convert inches to feet if inches are 12 or more
    if (inchesValue >= 12) {
      feetValue += Math.floor(inchesValue / 12);
      inchesValue %= 12;
    }

    return {
      feet: feetValue,
      inches: inchesValue,
    };
  };

  const formatValue = (value: string | number) => {
    if (isFocused) {
      return value.toString();
    }

    const { feet: feetValue, inches: inchesValue } = convertToFeetAndInches(
      value.toString(),
    );

    return `${feetValue}' ${inchesValue}"`;
  };

  const parseValue = (value: string) => {
    if (!isFocused) {
      const [feetValue, inchesValue] = value
        .split(/[',"]/)
        .map((v) => parseInt(v.trim(), 10) || 0);
      return (feetValue * 100 + inchesValue).toString();
    }

    return value;
  };

  return (
    <InputGroup size={'xs'} {...props}>
      {!!icon && (
        <InputRightElement pointerEvents="none">{icon}</InputRightElement>
      )}
      <NumberInput
        min={0}
        defaultValue={feet * 100 + inches}
        format={formatValue}
        parse={parseValue}
        onBlur={(e) => {
          const { feet: feetValue, inches: inchesValue } =
            convertToFeetAndInches(e.target.value);
          handleChange(feetValue, inchesValue);
        }}
      >
        <NumberInputField
          ref={numberInputRef}
          data-testid="construction-input"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              numberInputRef.current?.blur();
            }
          }}
        />
      </NumberInput>
    </InputGroup>
  );
};
