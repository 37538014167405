import { Prisma } from '@tb/gc-prisma';
import { z } from 'zod';

export const FilterTypes = z.enum([
  'contains',
  'notContains',
  'equals',
  'notEqual',
  'startsWith',
  'endsWith',
  'blank',
  'notBlank',
  'search',
]);

export type FilterType = z.infer<typeof FilterTypes>;

export const TextFilter = z.object({
  filterType: FilterTypes,
  filter: z.string(),
});

export type TextFilter = z.infer<typeof TextFilter>;

/**
 * Placeholder if we want to enhance search support
 */
// function convert(str: string[], filterType: FilterType) {
//   switch (filterType) {
//     case 'contains':
//       return str.join('&');
//   }
// }
/**
 * Converts filters into strings for use with postgres search
 */
export function filterToString(textFilter: TextFilter) {
  const { filter } = textFilter;

  const splat = filter.trim().replace(/\s+/g, ' ').replace(/\s/g, ' & ');

  const toReturn = splat;

  return toReturn;
}

export function formatFilter(textFilter: TextFilter): Prisma.StringFilter {
  const { filterType, filter } = textFilter;
  const base: Prisma.StringFilter = { mode: 'insensitive' };
  switch (filterType) {
    case 'contains':
      return {
        ...base,
        contains: filter,
        mode: 'insensitive',
      };

    default:
      return {
        ...base,
        search: filterToString(textFilter),
      };
  }
}
