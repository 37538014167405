import { IconButton, IconButtonProps } from '@chakra-ui/react';

export const ActionIconButton = (
  props: {
    'action-icon': JSX.Element;
    'aria-label': string;
  } & IconButtonProps,
) => (
  <IconButton
    {...props}
    size={'sm'}
    backgroundColor={'complete.500'}
    color={'brand.white'}
    borderRadius={'50%'}
    aria-label={props['aria-label']}
    icon={props.icon}
    _hover={{ backgroundColor: 'complete.900' }}
    _active={{ backgroundColor: 'complete.900' }}
  />
);
